import { Status } from "../types/withdraw"

export class WithdrawFilter {
  static status (status?: Status): string {
    switch (status) {
      case Status.REQUESTED:
        return 'Requested'
      case Status.REJECTED:
        return 'Rejected'
      case Status.APPROVED:
        return 'Approved'
      case Status.FINISHED:
        return 'Finished'
      default:
        return '' 
    }
  }
}