<template>
  <div aria-live="polite" aria-atomic="true" class="position-relative">
    <div class="toast-container p-2 top-0 start-50 translate-middle-x">
      <div ref="toastRef" class="toast align-items-center border-0" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex rounded" :class="[bgClass, textClass]">
          <div class="toast-body">{{ message }}</div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" @click="hide()"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Toast } from 'bootstrap';
import { onMounted, ref } from 'vue';

const toastRef = ref<HTMLDivElement>()
let toast: Toast

onMounted(() => {
  if (toastRef.value) {
    toast = new Toast(toastRef.value)
  }
})

const bgClass = ref<string>()
const textClass = ref<string>()
const message = ref<string>()

const info = (msg: string) => {
  if (toast) {
    bgClass.value = 'bg-info'
    textClass.value = 'text-dark'
    message.value = msg
    toast.show()
  }
}

const success = (msg: string) => {
  if (toast) {
    bgClass.value = 'bg-success'
    textClass.value = 'text-white'
    message.value = msg
    toast.show()
  }
}

const warning = (msg: string) => {
  if (toast) {
    bgClass.value = 'bg-warning'
    textClass.value = 'text-dark'
    message.value = msg
    toast.show()
  }
}

const error = (msg: string) => {
  if (toast) {
    bgClass.value = 'bg-danger'
    textClass.value = 'text-white'
    message.value = msg
    toast.show()
  }
}

const hide = () => {
  if (toast) {
    toast.hide()
  }
}

defineExpose({
  info,
  success,
  warning,
  error,
  hide
})
</script>