import { CancelReason, Status } from "../../../arrangement"
import { Filters } from "./index"

export interface ArrangementFilters extends Filters {
	studentId?: string
	teacherId: string
	courseId?: string
	status: Status
	cancelReason: CancelReason
}

export class ArrangementFiltersImpl implements ArrangementFilters {
	studentId?: string
	teacherId: string
	courseId?: string
	status: Status
	cancelReason: CancelReason

	constructor(teacherId: string) {
		this.teacherId = teacherId
		this.status = Status.ALL
		this.cancelReason = CancelReason.ALL
	}

	reset(): void {
		this.studentId = undefined
		this.courseId = undefined
		this.status = Status.ALL
		this.cancelReason = CancelReason.ALL
	}
}