<template>
  <div class="card">
    <div class="card-header d-lg-flex align-items-center justify-content-between">
      <h3 class="mb-0">Withdraw History</h3>
      <div class="d-flex justify-content-between">
        <select class="form-select ms-3" v-model="filters.status" @change="onStatusChange">
          <option v-for="(option, index) in WithdrawStatusOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
        </select>
      </div>
    </div>
    
    <div class="table-responsive border-0">
      <table class="table mb-0">
        <thead class="table-light">
          <tr>
            <th scope="col" class="border-0">#</th>
            <th scope="col" class="border-0">Status</th>
            <th scope="col" class="border-0">Date</th>
            <th scope="col" class="border-0">Amount</th>
            <th scope="col" class="border-0">Comment</th>
          </tr>
        </thead>
        <tbody v-if="withdraws && withdraws?.length > 0">
          <tr v-for="(withdraw, index) in withdraws" :key="withdraw.id">
            <th scope="row" class="align-middle border-top-0">{{ IndexFilter.index(pagination.currentPage, pagination.pageSize, index) }}</th>
            <td class="align-middle border-top-0">{{ WithdrawFilter.status(withdraw.status) }}</td>
            <td class="align-middle border-top-0">
							<div>{{ DateTimeFilter.date(withdraw.dateTime) }}</div>
							<div>{{ DateTimeFilter.time(withdraw.dateTime) }}</div>
						</td>
            <td class="align-middle border-top-0">{{ NumberFilter.money(withdraw.value) }}</td>
            <td class="align-middle border-top-0">{{ withdraw.remark }}</td>
          </tr>
        </tbody>
        <caption v-else style="text-align: center;">No Withdrawal</caption>
      </table>

      <it-pagination class="my-2 mx-4 float-end" :total="pagination.total" :page-size="pagination.pageSize" v-model:current-page="pagination.currentPage"></it-pagination>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref, watch } from 'vue'
import withdrawAPI from '../../api/withdraw'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { IndexFilter } from '../../filters/indexFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { WithdrawFilter } from '../../filters/withdrawFilter'
import { toast } from '../../injection'
import { WithdrawStatusOptions } from '../../types/common/option/withdrawOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { WithdrawFilters, WithdrawFiltersImpl } from '../../types/common/query/filters/withdrawFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { WithdrawSearcherImpl } from '../../types/common/query/searcher/withdrawSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Withdraw } from '../../types/withdraw'
import ItPagination from '../ui/ItPagination.vue'

const props = defineProps<{
  teacherId: string
}>()

const toastRef = inject(toast)

const loading = ref<boolean>(false)

const onStatusChange = function () {
	doListWithdraw()
}

const withdraws = ref<Withdraw[]>()

onMounted(() => {
	doListWithdraw()
})

// Query withdraw
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<WithdrawFilters>(new WithdrawFiltersImpl(props.teacherId))
const searcher = reactive<Searcher>(new WithdrawSearcherImpl())
const sorts = [new SortImpl('dateTime', Direction.DESC)]

const doListWithdraw = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	withdrawAPI.list(query).then((response: AxiosResponse<Result<ListObject<Withdraw>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			withdraws.value = listObject.objects
			pagination.total = listObject.total
		} else {
			toastRef?.value?.error(result.message)
		}
	}).catch((error: AxiosError) => {
		toastRef?.value?.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListWithdraw()
})
</script>

<style scoped>

.time {
	display: flex;
	justify-content: space-between;
}
</style>