import { Result } from "../types/common/result"
import { Course, SerieCourse } from "../types/course"
import service from "./api-service"

const api = '/course'

const courseAPI = {  
  findBySerieId (serieId: string) {
    return service.get<Result<SerieCourse[]>>(api + '/findBySerieId', { 
      params: {
        serieId: serieId
      }
    })
  },

  findById (id: string) {
    return service.get<Result<Course>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },
}

export default courseAPI