export enum Status {
  ALL = '',
  REQUESTED = 'REQUESTED', // 已申请
  REJECTED = 'REJECTED', // 已拒绝
  APPROVED = 'APPROVED', // 已同意，处理中
  FINISHED = 'FINISHED', // 已完成
}

export interface Withdraw {
  id: string
  status: Status
  value: number
  remark: string
  dateTime: Date

  teacherId: string
  teacherNickname: string
}