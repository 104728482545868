import { AuthTeacher } from '../types/auth'

const TEACHER = 'teacher'
const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'

const storage = {
    getTeacher (): AuthTeacher | undefined {
      const teacherJson = localStorage.getItem(TEACHER)
      return teacherJson? JSON.parse(teacherJson as string) : undefined
    },

    setTeacher (teacher: AuthTeacher): void {
      const teacherJson = JSON.stringify(teacher)
      localStorage.setItem(TEACHER, teacherJson)
    },

    removeTeacher (): void {
      localStorage.removeItem(TEACHER)
    },

    getAccessToken (): string | undefined {
      return localStorage.getItem(ACCESS_TOKEN) || undefined
    },

    setAccessToken (accessToken: string): void {
      localStorage.setItem(ACCESS_TOKEN, accessToken)
    },

    removeAccessToken (): void {
      localStorage.removeItem(ACCESS_TOKEN)
    },

    getRefreshToken (): string | undefined {
      return localStorage.getItem(REFRESH_TOKEN) || undefined
    },

    setRefreshToken (refreshToken: string): void {
      localStorage.setItem(REFRESH_TOKEN, refreshToken)
    },

    removeRefreshToken (): void {
      localStorage.removeItem(REFRESH_TOKEN)
    },

    clean (): void {
        this.removeTeacher()
        this.removeAccessToken()
        this.removeRefreshToken()
    }
}

export default storage