<template>
  <div v-if="active" class="bs-stepper-pane fade" :class="{active: active}" role="tabpanel">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance } from 'vue';

const props = defineProps<{
  step: number
}>()

const instance = getCurrentInstance()

const active = computed(() => {
  return props.step == instance?.parent?.props.activeStep
})
</script>