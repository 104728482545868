export interface Searcher {
	field: string
	keyword: string

	reset (): void
}

export class SearcherImpl implements Searcher {
	field = ''
	keyword = ''

	reset (): void {
			this.keyword = ''
	}
}