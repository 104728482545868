<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">Certificates</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <update-teacher-certificates ref="updateTeacherCertificatesRef" :teacher="teacher"></update-teacher-certificates>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref } from 'vue'
import teacherAPI from '../../api/teacher'
import UpdateTeacherCertificates from '../../components/me/UpdateTeacherCertificates.vue'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Teacher, TeacherImpl } from '../../types/teacher'
import { cloneFrom } from '../../utils/clone'

const toastRef = inject(toast)

const store = useStore()

const teacher = reactive<Teacher>(new TeacherImpl(store.getters.id))

const updateTeacherCertificatesRef = ref()

onMounted(() => {
  doFindTeacher()
})

const doFindTeacher = function () {
  teacherAPI.findById(store.getters.id).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(teacher, result.data)
      updateTeacherCertificatesRef.value.refresh(teacher)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}
</script>
