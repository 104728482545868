<template>
  <it-dialog :id="id" :header="true" :footer="true" @show="onShow">
    <template #header>
      <h4 class="modal-title">Finish Class</h4>
    </template>
    <template #body>
      <form ref="finishArrangementForm" :class="{'was-validated': finishArrangementFormValidated}" novalidate>
        <!-- Alert -->
        <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
        <!-- Star -->
        <div>
          <label for="star" class="form-label">Star</label>
          <it-rate ref="starRate" v-model="finishArrangement.star" id="star" name="star" required @change="onStarRateChange"></it-rate>
          <div class="invalid-feedback" :class="{invalid: starRateInvalid}">You must select star.</div>
        </div>
        <!-- Score -->
        <div class="mt-3" v-if="arrangementLessonType === LessonType.TEST">
          <label for="lessonTestScore" class="form-label">Score</label>
          <div class="input-group">
            <input ref="lessonTestScoreInput" v-model.trim="finishArrangement.lessonTestScore" type="number" id="lessonTestScore" class="form-control" name="lessonTestScore" placeholder="Test Score" required @input="onLessonTestScoreInput" />
            <span class="input-group-text"> / {{arrangementLessonTotalTestScore}}</span>
          </div>
          <div class="invalid-feedback" :class="{invalid: lessonTestScoreInputInvalid}">You must input valid test score.</div>
        </div>
        <!-- Remark -->
        <div class="mt-3">
          <label for="remark" class="form-label">Remark (Optional)</label>
          <textarea rows="5" v-model.trim="finishArrangement.remark" id="remark" class="form-control" name="remark" />
        </div>
      </form>
    </template>
    <template #footer>
      <it-button kind="secondary" size="sm" data-bs-dismiss="modal">Cancel</it-button>
      <it-button kind="primary" size="sm" :loading="finishing" @click="doFinishArrangement">Finish</it-button>
    </template>
  </it-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, reactive, ref, toRef } from 'vue'
import arrangementAPI from '../../api/arrangement'
import { toast } from '../../injection'
import { Arrangement, FinishArrangement, FinishArrangementImpl } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import { Type as LessonType } from '../../types/lesson'
import ItButton from '../ui/ItButton.vue'
import ItDialog from '../ui/ItDialog.vue'
import ItRate from '../ui/ItRate.vue'

const props = defineProps<{
  arrangementId: string,
  arrangementLessonType: LessonType,
  arrangementLessonTotalTestScore?: number,
  id: string
}>()

const emit = defineEmits<{
  (e: 'success'): void
}>()

const toastRef = inject(toast)

const arrangementIdRef = toRef(props, 'arrangementId')

const message = ref<string>('')

const starRateInvalid = ref<boolean>(false)
const onStarRateChange = function () {
  if (finishArrangement.star) starRateInvalid.value = false
  else starRateInvalid.value = true
}

const lessonTestScoreInput = ref<HTMLInputElement>()
const lessonTestScoreInputInvalid = ref<boolean>(false)
const onLessonTestScoreInput = function() {
  if (finishArrangement.lessonTestScore && finishArrangement.lessonTestScore > 0 && finishArrangement.lessonTestScore <= props.arrangementLessonTotalTestScore!) {
    lessonTestScoreInputInvalid.value = false
  } else {
    lessonTestScoreInputInvalid.value = true
  }
}

const finishArrangement = reactive<FinishArrangement>(new FinishArrangementImpl(props.arrangementId))
const finishArrangementForm = ref<HTMLFormElement>()

const finishing = ref<boolean>(false)
const finishArrangementFormValidated = ref<boolean>(false)

const onShow = function () {
  message.value = ''
  starRateInvalid.value = false
  finishArrangementFormValidated.value = false
  finishArrangement.reset()
}

const doFinishArrangement = function () {
  finishArrangementFormValidated.value = true
  if (finishArrangement.star) {
    finishing.value = true
    finishArrangement.id = arrangementIdRef.value
    arrangementAPI.finish(finishArrangement).then((response: AxiosResponse<Result<Arrangement>>) => {
      const result = response.data
      if (result.success) {
        finishArrangementFormValidated.value = false
        toastRef?.value?.success('Success to finish')
        emit('success')
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      finishing.value = false
    })
  } else {
    starRateInvalid.value = true
  }
}
</script>

<style lang="scss" scoped>

.invalid {
  display: block;
}

</style>