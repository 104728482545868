import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { store } from '../store'
import Auth from '../views/Auth.vue'
import Course from '../views/course/Course.vue'
import Courses from '../views/course/Courses.vue'
import Salary from '../views/finance/Salary.vue'
import Statements from '../views/finance/Statements.vue'
import Withdraw from '../views/finance/Withdraw.vue'
import Accounts from '../views/me/Accounts.vue'
import Certificates from '../views/me/Certificates.vue'
import LinkedAccounts from '../views/me/LinkedAccounts.vue'
import Profile from '../views/me/Profile.vue'
import Security from '../views/me/Security.vue'
import Settings from '../views/me/Settings.vue'
import TimeSlots from '../views/me/TimeSlots.vue'
import NotAuthorized from '../views/NotAuthorized.vue'
import NotFound from '../views/NotFound.vue'
import Arrangement from '../views/schedule/Arrangement.vue'
import Arrangements from '../views/schedule/Arrangements.vue'
import Classroom from '../views/schedule/Classroom.vue'
import Schedule from '../views/schedule/Schedule.vue'
import Supplement from '../views/Supplement.vue'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'notFound',
    path: '/404',
    component: NotFound
  },
  {
    name: 'notAuthorized',
    path: '/403',
    component: NotAuthorized
  },
  {
    name: 'auth',
    path: '/auth',
    component: Auth
  },
  {
    name: 'supplement',
    path: '/supplement',
    component: Supplement
  },
  {
    name: 'profile',
    path: '/profile',
    component: Profile
  },
  {
    name: 'accounts',
    path: '/accounts',
    component: Accounts
  },
  {
    name: 'certificates',
    path: '/certificates',
    component: Certificates
  },
  {
    name: 'security',
    path: '/security',
    component: Security
  },
  {
    name: 'linkedAccounts',
    path: '/linked-accounts',
    component: LinkedAccounts
  },
  {
    name: 'timeSlots',
    path: '/timeSlots',
    component: TimeSlots
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings
  },
  {
    name: 'courses',
    path: '/courses',
    component: Courses
  },
  {
    name: 'course',
    path: '/course/:id',
    component: Course
  },
  {
    name: 'schedule',
    path: '/schedule',
    component: Schedule
  },
  {
    name: 'arrangements',
    path: '/arrangements',
    component: Arrangements
  },
  {
    name: 'arrangement',
    path: '/arrangement/:id',
    component: Arrangement
  },
  {
    name: 'classroom',
    path: '/classroom',
    component: Classroom
  },
  {
    name: 'salary',
    path: '/salary',
    component: Salary
  },
  {
    name: 'withdraw',
    path: '/withdraw',
    component: Withdraw
  },
  {
    name: 'statements',
    path: '/statements',
    component: Statements
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'auth' && !store.getters.isAuth) {
    next({ name: 'auth' })
  } else {
    next()
  }
})

export default router