import { Level } from "../types/teacher"

export class TeacherFilter {
  static locked (locked?: boolean): string {
    if (locked == undefined) return ''
    else return locked? 'Locked' : 'Normal'
  }

  static complete (complete?: boolean): string {
    if (complete == undefined) return ''
    else return complete? 'Complete' : 'Uncomplete'
  }

  static certificated (certificated?: boolean): string {
    if (certificated == undefined) return ''
    else return certificated? 'Certified' : 'Uncertified'
  }

  static fullName (firstName?: string, lastName?: string): string {
    return firstName + ' ' + lastName
  }

  static level (level?: Level): string {
    switch (level) {
      case Level.BRONZE: return 'Bronze'
      case Level.SILVER: return 'Silver'
      case Level.GOLD: return 'Gold'
      case Level.PLATINUM: return 'Platinum'
      default: return '' 
    }
  }
}