import { Arrangement, CancelArrangement, CreateArrangementMeeting, FinishArrangement } from "../types/arrangement"
import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import service from "./api-service"

const api = '/arrangement'

const arrangementAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Arrangement>>>(api + '/list', query)
  },
    
  findById (id: string) {
    return service.get<Result<Arrangement>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  findByTeacherIdAndDate (teacherId: string, date: Date) {
    return service.get<Result<Arrangement[]>>(api + '/findByTeacherIdAndDate', { 
      params: {
        teacherId: teacherId,
        timestamp: date.getTime()
      }
    })
  },
  
  createMeeting (createArrangementMeeting: CreateArrangementMeeting) {
    return service.put<Result<Arrangement>>(api + '/createMeeting', createArrangementMeeting)
  },

  finish (finishArrangement: FinishArrangement) {
    return service.put<Result<Arrangement>>(api + '/finish', finishArrangement)
  },

  cancel (cancelArrangement: CancelArrangement) {
    return service.put<Result<Arrangement>>(api + '/cancel', cancelArrangement)
  },
}

export default arrangementAPI