import { Status } from "../types/teachingTimeSlots"

export class TimeSlotFilter {

  static status (status: Status): string {
    switch (status) {
      case Status.FREE: return 'Unavailable'
      case Status.AVAILABLE: return 'Available'
      case Status.BOOKED: return 'Booked'
      default: return '' 
    }
  }
}