<template>
  <div class="d-flex">
    <i v-for="star in stars" :key="star.index" class="me-1" :class="star.class" @click="onClick(star)"></i>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue?: number,
  disabled?: boolean
}>()

const emit = defineEmits<{
  (e: 'change', rank: number): void
  (e: 'update:modelValue', rank: number): void
}>()

class Star {
  index: number
  class: string = 'bi-star'

  constructor(index: number) {
    this.index = index
  }
}

const initStars: Star[] = [new Star(1), new Star(2), new Star(3), new Star(4), new Star(5)]
const stars = computed(() => {
  return initStars.map(item => {
    if (props.modelValue) {
      if (item.index <= props.modelValue) {
        item.class = 'bi-star-fill'
      } else if (item.index > props.modelValue) {
        item.class = 'bi-star'
      } else {
        item.class = 'bi-star-half'
      }
      return item
    } else {
      return item
    }
  })
})

const onClick = function (star: Star) {
  if (!props.disabled) {
    emit('change', star.index)
    emit('update:modelValue', star.index)
  }
}
</script>