export enum Type {
	LECTURE = 'LECTURE',
	TEST = 'TEST'
}

export interface Lesson {
	id: string
	type: Type
	totalTestScore?: number
	name: string
	fullName: string
	number: number
	order: number
	slide: string
	courseId: string
  courseName: string
	moduleId: string
	moduleName: string
}

export interface SimpleLesson {
	id: string
	type: Type
	name: string
	fullName: string
	number: number
	order: number
	slide: string
	courseId: string
	moduleId: string
	moduleName: string
}