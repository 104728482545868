<template>
  <div>  
    <it-result kind="success" title="Congratulations" description="Thanks for completing your info. Please go on to set your time slots for teaching, then you are ready to teach.">
      <hr />
      <it-button kind="success" :loading="creating" @click="createTeachingTimeSlots">Setting Teaching Time Slots</it-button>
    </it-result>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { Actions, useStore } from '../../store'
import { Result } from '../../types/common/result'
import { CreateTeacherTeachingTimeSlotsImpl, Teacher } from '../../types/teacher'
import ItButton from '../ui/ItButton.vue'
import ItResult from '../ui/ItResult.vue'

const toastRef = inject(toast)

const router = useRouter()
const store = useStore()

const creating = ref<boolean>(false)
const createTeachingTimeSlots = function () {
  const createTeacherTeachingTimeSlots = new CreateTeacherTeachingTimeSlotsImpl(store.getters.id)
  teacherAPI.createTeachingTimeSlots(createTeacherTeachingTimeSlots).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      const teacher = result.data
      store.dispatch(Actions.COMPLETE_TEACHER_INFO, teacher).then(() => {
        router.push({
          name: 'timeSlots'
        })
      })
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}
</script>