<template>
  <div class="row align-items-center">
    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
      <!-- Bg -->
      <div class="pt-16 rounded-top-md" :style="backgroundStyle"></div>
      <div class="d-flex align-items-end justify-content-between bg-white px-4 pt-2 pb-4 rounded-none rounded-bottom-md shadow-sm">
        <div class="d-flex align-items-center">
          <div class="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
            <img :src="teacher.avatar" class="avatar-xl rounded-circle border border-4 border-white" />
          </div>
          
          <div class="lh-1">
            <div class="d-flex align-items-center">
              <h2 class="mb-0">{{ TeacherFilter.fullName(teacher.firstName, teacher.lastName) }}</h2>
              <router-link v-if="!teacher.vendor" :to="{name: 'salary'}">
                <h4 class="ms-3 mb-0"><i class="bi-award-fill" :style="[teacherLevelStyle]"></i></h4>
              </router-link>
              <router-link v-if="!teacher.vendor" :to="{name: 'certificates'}">
                <h4 class="ms-3 mb-0"><i class="bi-postcard-fill" :style="[teacher.certified? {color: 'green'} : {color: 'gray'}]"></i></h4>
              </router-link>
              <router-link v-if="!teacher.vendor" :to="{name: 'salary'}">
                <h4 class="ms-3 mb-0"><it-rate v-model="teacher.rank" disabled></it-rate></h4>
              </router-link>
            </div>
            <p class="mb-0 d-block">{{ teacher.nickname }}</p>
          </div>
        </div>
        <div>
          <router-link class="btn btn-outline-primary d-none d-md-block" :to="{name: 'schedule'}">Go to Schedule</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive } from 'vue'
import teacherAPI from '../api/teacher'
import ItRate from '../components/ui/ItRate.vue'
import { TeacherFilter } from '../filters/teacherFilter'
import { toast } from '../injection'
import { useStore } from '../store'
import { Result } from '../types/common/result'
import { Level, Teacher, TeacherImpl } from '../types/teacher'
import { cloneFrom } from '../utils/clone'

const toastRef = inject(toast)

const store = useStore()

let background: string = ''
switch(import.meta.env.VITE_APP_BRAND) {
  case 'CetuSchool': {
    background = 'url(/background/cetuschool.jpg) no-repeat'
    break
  }
  case 'DDTalk': {
    background = 'url(/background/ddtalk.jpg) no-repeat'
    break
  }
}

const backgroundStyle = {
  background: background,
  backgroundSize: 'cover'
}

onMounted(() => {
  doFindTeacher()
})

const getTeacherLevelColor = function (teacher: Teacher) {
  switch (teacher.level) {
    case Level.BRONZE: return 'chocolate'
    case Level.SILVER: return 'silver'
    case Level.GOLD: return 'gold'
    case Level.PLATINUM: return 'silver'
    default: return 'gray'
  }
}

const teacher = reactive<Teacher>(new TeacherImpl(store.getters.id))
const doFindTeacher = function () {
  teacherAPI.findById(store.getters.id).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(teacher, result.data)
      teacherLevelStyle.color = getTeacherLevelColor(teacher)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const teacherLevelStyle = reactive({
  color: 'gray'
})
</script>

<style lang="scss" scoped>

.bronze {
  color: chocolate;
}

.silver {
  color: silver;
}

.gold {
  color: gold;
}
</style>