import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { Withdraw } from "../types/withdraw"
import service from "./api-service"

const api = '/withdraw'

const withdrawAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Withdraw>>>(api + '/list', query)
  }    
}

export default withdrawAPI