<template>
  <!-- Modal -->
  <div class="modal fade" :id="id" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div v-if="header" class="modal-header">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div v-if="footer" class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

interface Props {
  id: string,
  header?: boolean,
  footer?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  header: true,
  footer: true
})

const emit = defineEmits<{
  (e: 'show'): void,
  (e: 'shown'): void,
  (e: 'hide'): void,
  (e: 'hidden'): void
}>()

onMounted(() => {
  var modal: HTMLElement | null = document.getElementById(props.id)

  if (modal) {
    modal.addEventListener('show.bs.modal', function (event) {
      emit('show')
    })

    modal.addEventListener('shown.bs.modal', function (event) {
      emit('shown')
    })

    modal.addEventListener('hide.bs.modal', function (event) {
      emit('hide')
    })

    modal.addEventListener('hidden.bs.modal', function (event) {
      emit('hidden')
    })
  }
})
</script>
