type value = string | boolean | number

export interface Option {
	label: string
	value: value
}

export class OptionImpl implements Option {
	label: string
	value: value

	constructor (label: string, value: value) {
		this.label = label
		this.value = value
	}
}