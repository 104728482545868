import { Status } from "../../../withdraw"
import { Filters } from "./index"

export interface WithdrawFilters extends Filters {
	teacherId: string
	status?: Status
}

export class WithdrawFiltersImpl implements WithdrawFilters {
	teacherId: string
	status: Status

	constructor(teacherId: string) {
		this.teacherId = teacherId
		this.status = Status.ALL
	}

	reset(): void {
		this.status = Status.ALL
	}
}