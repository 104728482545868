<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">Download Classroom</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <h4 class="mb-4">Zoom Cloud Meeting for PC</h4>
      <div>
        <it-button kind="primary" outline @click="doDownloadZoomForPC" style="width: 200px;">
          <i class="bi-apple"></i>
          <span class="ms-3">Mac</span>
        </it-button>
        <it-button kind="primary" outline @click="doDownloadZoomForMac" class="ms-4" style="width: 200px;">
          <i class="bi-windows"></i>
          <span class="ms-3">Windows</span>
        </it-button>
      </div>
      <h4 class="my-4">Zoom Cloud Meeting for Pad</h4>
      <div>
        <it-button kind="primary" outline @click="doDownloadZoomForIOS" style="width: 200px;">
          <i class="bi-apple"></i>
          <span class="ms-3">App Store</span>
        </it-button>
        <it-button kind="primary" outline @click="doDownloadZoomForAndroid" class="ms-4" style="width: 200px;">
          <i class="bi-google-play"></i>
          <span class="ms-3">Google Play</span>
        </it-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ItButton from '../../components/ui/ItButton.vue';

const doDownloadZoomForPC = function () {
  window.open('https://zoom.us/download')
}

const doDownloadZoomForMac = function () {
  window.open('https://zoom.us/download')
}

const doDownloadZoomForIOS = function () {
  window.open('https://apps.apple.com/us/app/id546505307?platform=ipad')
}

const doDownloadZoomForAndroid = function () {
  window.open('https://play.google.com/store/apps/details?id=us.zoom.videomeetings')
}
</script>
