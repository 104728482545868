import { Result } from "../types/common/result"
import { CompleteTeacherAcademicInfo, CompleteTeacherBasicInfo, CreateTeacherTeachingTimeSlots, Teacher, UpdateTeacherAvatar, UpdateTeacherCertificates, UpdateTeacherEmail, UpdateTeacherInfo, UpdateTeacherMobile, UpdateTeacherShow, UpdateTeacherZoneId } from "../types/teacher"
import service from "./api-service"
import fileService from "./fille-service"

const api = '/teacher'

const teacherAPI = {
  
  findById (id: string) {
    return service.get<Result<Teacher>>(api + '/findById', { 
        params: {
            id: id
        }
    })
  },

  createTeachingTimeSlots (createTeacherTeachingTimeSlots: CreateTeacherTeachingTimeSlots) {
    return service.post<Result<Teacher>>(api + '/teachingTimeSlots', createTeacherTeachingTimeSlots)
  },

  uploadAvatar (file: File) {
    let formData = new FormData()
    formData.append('file', file)
    return fileService.post<Result<string>>(api + '/uploadAvatar', formData)
  },

  uploadShow (file: File) {
    let formData = new FormData()
    formData.append('file', file)
    return fileService.post<Result<string>>(api + '/uploadShow', formData)
  },

  uploadCertificatePhoto (file: File) {
    let formData = new FormData()
    formData.append('file', file)
    return fileService.post<Result<string>>(api + '/uploadCertificatePhoto', formData)
  },

  completeBasicInfo (completeTeacherBasicInfo: CompleteTeacherBasicInfo) {
    return service.put<Result<Teacher>>(api + '/completeBasicInfo', completeTeacherBasicInfo)
  },

  completeAcademicInfo (completeTeacherAcademicInfo: CompleteTeacherAcademicInfo) {
    return service.put<Result<Teacher>>(api + '/completeAcademicInfo', completeTeacherAcademicInfo)
  },

  updateEmail (updateTeacherEmail: UpdateTeacherEmail) {
    return service.put<Result<Teacher>>(api + '/updateEmail', updateTeacherEmail)
  },

  updateMobile (updateTeacherMobile: UpdateTeacherMobile) {
    return service.put<Result<Teacher>>(api + '/updateMobile', updateTeacherMobile)
  },

  updateZoneId (updateTeacherZoneId: UpdateTeacherZoneId) {
    return service.put<Result<Teacher>>(api + '/updateZoneId', updateTeacherZoneId)
  },

  updateAvatar (updateTeacherAvatar: UpdateTeacherAvatar) {
    return service.put<Result<Teacher>>(api + '/updateAvatar', updateTeacherAvatar)
  },

  updateShow (updateTeacherShow: UpdateTeacherShow) {
    return service.put<Result<Teacher>>(api + '/updateShow', updateTeacherShow)
  },

  updateCertificates (updateTeacherCertificates: UpdateTeacherCertificates) {
    return service.put<Result<Teacher>>(api + '/updateCertificates', updateTeacherCertificates)
  },

  updateInfo (updateTeacherInfo: UpdateTeacherInfo) {
    return service.put<Result<Teacher>>(api + '/updateInfo', updateTeacherInfo)
  },
}

export default teacherAPI