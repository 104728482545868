<template>
  <div>
    <h4 class="mb-4">Mobile</h4>
    
    <form ref="updateTeacherMobileForm" :class="{'was-validated': updateTeacherMobileFormValidated}" novalidate>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
      <!-- Mobile -->
      <div class="mb-3">
        <label for="mobile" class="form-label">Mobile</label>
        <div class="input-group">
          <select ref="countryCodeSelect" v-model="updateTeacherMobile.region" class="form-select" required @change="onCountryCodeChange">
            <option value="null" disabled selected>Country / Region</option>
            <option v-for="phoneCountryCode in phoneCountryCodes" :key="phoneCountryCode.region" :value="phoneCountryCode.region">{{ phoneCountryCode.country }}</option>
          </select>
          <input ref="mobileInput" v-model.trim="updateTeacherMobile.mobile" type="text" id="mobile" class="form-control" name="mobile" placeholder="Mobile number" required @input="onMobileInput()" />
          <it-button kind="primary" class="rounded-end" outline style="width: 120px;" :loading="sendingOtp" :disabled="disableOtpButton" @click="doSendMobileOtp()">{{ optButtonText }}</it-button>
          <div class="invalid-feedback" :class="{invalid: countryCodeSelectInvalid}">You must select country code.</div>
          <div class="invalid-feedback" :class="{invalid: mobileInputInvalid}">You must input valid mobile number.</div>
        </div>
      </div>
      <!-- OTP -->
      <div class="mb-3">
        <label for="otp" class="form-label">OTP</label>
        <input v-model.trim="updateTeacherMobile.otp" type="text" id="otp" class="form-control" name="otp" placeholder="One time password" required pattern="\d{6}" />
        <div class="invalid-feedback">You must input valid otp.</div>
      </div>
      <!-- Button -->
      <div class="d-grid">
        <it-button kind="primary" :loading="updating" @click="doUpdateTeacherMobile">Update Mobile</it-button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import Timer from 'tiny-timer'
import { inject, onMounted, reactive, ref } from 'vue'
import mobileAPI from '../../api/mobile'
import smsAPI from '../../api/sms'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { PhoneCountryCode } from '../../types/mobile'
import { Teacher, UpdateTeacherMobile, UpdateTeacherMobileImpl } from '../../types/teacher'
import { getCountryCodeFromRegion } from '../../utils/mobileUtils'
import ItButton from '../ui/ItButton.vue'

const props = defineProps<{
  teacher: Teacher
}>()

const toastRef = inject(toast)

const message = ref<string>('')

const optButtonText = ref<string>('Get OTP')
const disableOtpButton = ref<boolean>(false)
const sendingOtp = ref<boolean>(false)

// phone country code
const phoneCountryCodes = ref<PhoneCountryCode[]>()
const doFindAllPhoneCountryCodes = function () {
  mobileAPI.findAllCountryCode().then((response: AxiosResponse<Result<PhoneCountryCode[]>>) => {
    const result = response.data
    if (result.success) {
      phoneCountryCodes.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

// timer
let countDown = 30
const timer = new Timer({ interval: 1000, stopwatch: false })
timer.on('tick', () => {
  optButtonText.value = Number(countDown--).toString() + 's'
})
timer.on('done', () => {
  optButtonText.value = 'Get OTP'
  disableOtpButton.value = false
  countDown = 30
})

const countryCodeSelect = ref<HTMLSelectElement>()
const countryCodeSelectInvalid = ref<boolean>(false)
const onCountryCodeChange = function() {
  countryCodeSelect.value?.setCustomValidity('')
  countryCodeSelectInvalid.value = false

  setCountryCodeSelectStyle()
}

const mobileInput = ref<HTMLInputElement>()
const mobileInputInvalid = ref<boolean>(false)
const onMobileInput = function() {
  mobileInput.value?.setCustomValidity('')
  mobileInputInvalid.value = false
}

const doSendMobileOtp = function() {  
  if (!countryCodeSelect.value?.checkValidity()) {
    countryCodeSelectInvalid.value = true
    return
  }

  if (!mobileInput.value?.checkValidity()) {
    mobileInputInvalid.value = true
    return
  } 

  updateTeacherMobile.countryCode = getCountryCodeFromRegion(phoneCountryCodes.value, updateTeacherMobile.region)

  if (updateTeacherMobile.countryCode && updateTeacherMobile.mobile) {
    sendingOtp.value = true
    disableOtpButton.value = true
    smsAPI.sendUpdateMobileOtp(updateTeacherMobile.countryCode, updateTeacherMobile.mobile).then((response: AxiosResponse<Result<void>>) => {
      const result: Result<void> = response.data
      if (result.success) {
        toastRef?.value?.success('Send success')
        timer.start(countDown * 1000)
      } else {
        message.value = result.message
        disableOtpButton.value = false
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
      disableOtpButton.value = false
    }).finally(() => {
      sendingOtp.value = false
    })
  }
}

const updateTeacherMobile = reactive<UpdateTeacherMobile>(new UpdateTeacherMobileImpl(props.teacher))
const updateTeacherMobileForm = ref<HTMLFormElement>()

const updating = ref<boolean>(false)
const updateTeacherMobileFormValidated = ref<boolean>(false)

const doUpdateTeacherMobile = function () {
  updateTeacherMobileFormValidated.value = true
  if (updateTeacherMobileForm.value?.checkValidity()) {
    updating.value = true
    teacherAPI.updateMobile(updateTeacherMobile).then((response: AxiosResponse<Result<Teacher>>) => {
      const result = response.data
      if (result.success) {
        updateTeacherMobileFormValidated.value = false
        disableOtpButton.value = false
        updateTeacherMobile.otp = ''
        toastRef?.value?.success('Success to update')
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      updating.value = false
    })
  }
}

const refresh = function (teacher: Teacher) {
  updateTeacherMobile.refresh(teacher)
  setCountryCodeSelectStyle()
}

const setCountryCodeSelectStyle = function() {
  if (countryCodeSelect.value) {
    if (updateTeacherMobile.region) {
      countryCodeSelect.value.style.color = 'black'
    } else {
      countryCodeSelect.value.style.color = 'gray'
    }
  }
}

onMounted(() => {
  setCountryCodeSelectStyle()
  doFindAllPhoneCountryCodes()
})

defineExpose({
  refresh
})
</script>

<style lang="scss" scoped>

.invalid {
  display: block;
}

.input-group {
  .form-select {
    flex-grow: 1;
  }

  .form-control {
    flex-grow: 5;
  }
} 

</style>