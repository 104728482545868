import { DateTime } from "luxon"
import storage from '../storage'

export function getThisMonday(): Date {
  const dateTime = getLocalDateTime()
  const monday = dateTime.startOf('week')
  return monday.toJSDate()
}

export function getThisDateOfWeek(index: number): Date {
  const dateTime = getLocalDateTime()
  const weekday = dateTime.startOf('week').plus({ days: index })
  return weekday.toJSDate()
}

export function getToday(): Date {
  const dateTime = getLocalDateTime()
  const today = dateTime.set({hour: 0, minute: 0, second: 0, millisecond: 0})
  return today.toJSDate()
}

export function getWeekday(date: Date): number {
  const teacher = storage.getTeacher()
  const dateTime = DateTime.fromJSDate(date).setZone(teacher?.zoneId)
  return dateTime.weekday
}

export function getLocalDateTime(): DateTime {
  const teacher = storage.getTeacher() 
  return DateTime.now().setZone(teacher?.zoneId)
}

export function isMinutesAfterNow(date: Date, minutes: number): boolean {
  const dateTime = DateTime.fromJSDate(date)
  const afterNow = DateTime.now().plus({minutes: minutes})
  return dateTime > afterNow
}