<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">Linked Accounts</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <!-- Facebook -->
      <div class="mb-5 d-md-flex">
        <div>
          <i class="bi-facebook color-facebook h1 me-3"></i>
        </div>
        <div>
          <h3 class="mb-0">Facebook</h3>
          <p>Enable one-click login and receive more personalized course recommendations.</p>
          <a href="#" class="btn btn-primary">Remove my Facebook Account</a>
        </div>
      </div>
      
      <!-- Google -->
      <div class="mb-5 d-md-flex border-top pt-5">
        <div>
          <i class="bi-google color-google h1 me-3"></i>
        </div>
        <div>
          <h3 class="mb-0">Google</h3>
          <p>Enable one-click login and receive more personalized course recommendations.</p>
          <a href="#" class="btn btn-outline-secondary">Link my Google Account</a>
        </div>
      </div>

      <!-- Twitter -->
      <div class="mb-5 d-md-flex border-top pt-5">
        <div>
          <i class="bi-twitter color-twitter h1 me-3"></i>
        </div>
        <div>
          <h3 class="mb-0">Twitter</h3>
          <p>Enable one-click login and receive more personalized course recommendations.</p>
          <a href="#" class="btn btn-outline-secondary">Link my Twitter Account</a>
        </div>
      </div>
    </div>
  </div>
</template>