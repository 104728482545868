<template>
  <earnings :teacher-id="teacherId"></earnings>
  <withdraws class="mt-4" :teacher-id="teacherId"></withdraws>
</template>

<script setup lang="ts">
import Earnings from '../../components/earning/Earnings.vue';
import Withdraws from '../../components/withdraw/Withdraws.vue';
import { useStore } from '../../store';

const store = useStore()
const teacherId = store.getters.id
</script>
