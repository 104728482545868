<template>
  <it-button class="slot" kind="secondary" outline :class="getClass()" :disabled="timeSlot.status === Status.BOOKED">
    <div>{{ DateTimeFilter.time(timeSlot.startTime) }}</div>
    <div>{{ TimeSlotFilter.status(timeSlot.status) }}</div>
    <div v-if="timeSlot.status === Status.BOOKED">
      <div>{{ enrollment.studentNickname }}</div>
    </div>
  </it-button>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref } from 'vue'
import enrollmentAPI from '../../../api/enrollment'
import { DateTimeFilter } from '../../../filters/dateTimeFilter'
import { TimeSlotFilter } from '../../../filters/timeSlotFilter'
import { toast } from '../../../injection'
import { Result } from '../../../types/common/result'
import { Enrollment, EnrollmentImpl } from '../../../types/enrollment'
import { Status, TimeSlot } from '../../../types/teachingTimeSlots'
import { cloneFrom } from '../../../utils/clone'
import ItButton from '../ItButton.vue'

const props = defineProps<{
  timeSlot: TimeSlot
}>()

const getClass = function () {
  switch (props.timeSlot.status) {
    case Status.FREE: return ''
    case Status.AVAILABLE: return 'available'
    case Status.BOOKED: return 'booked'
    default: return ''
  }
}

const toastRef = inject(toast)

const loading = ref<boolean>(false)
const enrollment = reactive<Enrollment>(new EnrollmentImpl(props.timeSlot.enrollmentId))
const doFindEnrollment = function() {
  loading.value = true
  enrollmentAPI.findById(props.timeSlot.enrollmentId).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(enrollment, result.data)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  if (props.timeSlot.status === Status.BOOKED && props.timeSlot.enrollmentId) {
    doFindEnrollment()
  }
})
</script>

<style lang="scss" scoped>
.slot {
  width: 100%;
}

.available {
  border: 1px solid green;
  background-color: green;
  color: white;
}

.booked {
  border: 1px solid blue;
  background-color: blue;
  color: white;
}
</style>