import { DateTime } from 'luxon'
import storage from '../storage'

export class DateTimeFilter {
  static year (date?: Date): string {
    return date? Number(DateTimeFilter.fromJSDate(date).year).toString() : ''
  }

  static date (date?: Date): string {
    return date? DateTimeFilter.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT) : ''
  }

  static datetime (date?: Date): string {
    return date? DateTimeFilter.fromJSDate(date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : ''
  }

  static hour (date?: Date): string {
    return date? DateTimeFilter.fromJSDate(date).toLocaleString({ hour: '2-digit' }) : ''
  }

  static time (date?: Date): string {
    return date? DateTimeFilter.fromJSDate(date).toLocaleString(DateTime.TIME_24_SIMPLE) : ''
  }

  static fromJSDate(date: Date): DateTime {
    const teacher = storage.getTeacher()
    return DateTime.fromJSDate(date).setZone(teacher?.zoneId)
  }
}