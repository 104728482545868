import storage from "../storage"
import { store } from "../store"

export class TeacherAuth {
	zoneId: string = store.state.teacher.zoneId
}

export class TeacherEmailOtpAuth extends TeacherAuth {
	email?: string
	otp?: string
}

export class TeacherEmailPasswordAuth extends TeacherAuth {
	email?: string
	password?: string
}

export class TeacherMobileOtpAuth extends TeacherAuth {
	countryCode: string = '001'
	mobile?: string
	otp?: string
}

export class AuthTeacher {
	id?: string
	avatar?: string
	nickname?: string
  zoneId: string
	basicInfoComplete?: boolean
	academicInfoComplete?: boolean
  complete?: boolean
	vendor?: boolean
	locked?: boolean

	constructor() {
		this.zoneId = storage.getTeacher()?.zoneId || Intl.DateTimeFormat().resolvedOptions().timeZone
	}
}

export interface TeacherAuthed {
	accessToken: string
	teacher: AuthTeacher
}