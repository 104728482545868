<template>
  <div class="d-flex">
    <it-avatar :src="avatarURL" size="xxl" class="flex-shrink-0"></it-avatar>
    <div v-if="editAvatar" class="ms-4">
      <div class="input-group">
        <input type="file" class="form-control" accept="image/*" @change="beforeUploadTeacherAvatar" required>
        <it-loading :loading="uploading"></it-loading>
      </div>
      <small class="mt-3 d-block">
        Upload your avatar. It must be in JPEG or PNG format, with the file size smaller than 1M.
      </small>
      <small class="mt-3 error" v-if="message">{{ message }}</small>
    </div>
    <div v-else class="ms-4 d-flex align-items-center justify-content-between" style="width: 100%;">
      <div>Your Avatar</div>
      <it-button kind="secondary" outline size="sm" @click="doEditTeacherAvatar">Change</it-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, ref } from 'vue'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { useModelWrapper } from '../../utils/modelWrapper'
import ItAvatar from '../ui/ItAvatar.vue'
import ItButton from '../ui/ItButton.vue'
import ItLoading from '../ui/ItLoading.vue'

export interface Props {
  avatar: string,
  edit: boolean
}

const props = withDefaults(defineProps<Props>(), {
  edit: true
})

const emit = defineEmits<{
  (e: 'update:avatar'): void,
  (e: 'update:edit'): void,
  (e: 'success', url: string): void
}>()

const toastRef = inject(toast)

const avatarURL = useModelWrapper(props, emit, 'avatar')
const editAvatar = useModelWrapper(props, emit, 'edit')

const uploading = ref<boolean>(false)
const message = ref<string>('')

const beforeUploadTeacherAvatar = function (event: Event) {
  const target = event.target as HTMLInputElement
  if (target.files && target.files[0]) {
    const file = target.files[0]
    // 判断大小
    if (file.size > 1024 * 1024) {
      message.value = 'Please upload image which size is less than 1M'
    } else {
      doUploadTeacherAvatar(file)
    }
  }
}

const doUploadTeacherAvatar = function (avatar: File) {
  uploading.value = true
  teacherAPI.uploadAvatar(avatar).then((response: AxiosResponse<Result<string>>) => {
    const result = response.data
    if (result.success) {
      avatarURL.value = result.data
      emit('success', result.data)
    } else {
      message.value = result.message
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    uploading.value = false
  })
}

const doEditTeacherAvatar = function () {
  editAvatar.value = true
}
</script>
