<template>
  <!-- Card -->
  <div class="card mb-3">
    <!-- Card header -->
    <div class="card-header border-bottom px-4 py-3">
      <h4 class="mb-0">Academic Information</h4>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <form ref="completeTeacherAcademicInfoForm" :class="{'was-validated': completeTeacherAcademicInfoFormValidated}" novalidate>
        <!-- Alert -->
        <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
        <!-- Show -->
        <teacher-show-uploader v-model:show="completeTeacherAcademicInfo.show"></teacher-show-uploader>
        <!-- Introduction -->
        <div class="mt-3">
          <label for="introduction" class="form-label">Self Introduction</label>
          <textarea rows="5" v-model.trim="completeTeacherAcademicInfo.introduction" id="introduction" class="form-control" name="introduction" required />
          <div class="invalid-feedback">You must input valid introduction.</div>
        </div>
        <!-- Certificates -->
        <div class="mt-3">
          <teacher-certificates :certificates="completeTeacherAcademicInfo.certificates"></teacher-certificates>
        </div>
        <!-- Button -->
        <div class="d-grid mt-3">
          <it-button kind="primary" :loading="completing" @click="doCompleteTeacherAcademicInfo">Complete Academic Info</it-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, reactive, ref } from 'vue'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { Actions, useStore } from '../../store'
import { Result } from '../../types/common/result'
import { CompleteTeacherAcademicInfo, CompleteTeacherAcademicInfoImpl, Teacher } from '../../types/teacher'
import TeacherCertificates from '../certificate/TeacherCertificates.vue'
import TeacherShowUploader from '../me/TeacherShowUploader.vue'
import ItButton from '../ui/ItButton.vue'

const emit = defineEmits<{
  (e: 'success'): void
}>()

const toastRef = inject(toast)

const store = useStore()

const message = ref<string>('')

const completeTeacherAcademicInfo = reactive<CompleteTeacherAcademicInfo>(new CompleteTeacherAcademicInfoImpl(store.getters.id))
const completeTeacherAcademicInfoForm = ref<HTMLFormElement>()

const completing = ref<boolean>(false)
const completeTeacherAcademicInfoFormValidated = ref<boolean>(false)

const doCompleteTeacherAcademicInfo = function () {
  completeTeacherAcademicInfoFormValidated.value = true
  if (completeTeacherAcademicInfoForm.value?.checkValidity()) {
    completing.value = true
    teacherAPI.completeAcademicInfo(completeTeacherAcademicInfo).then((response: AxiosResponse<Result<Teacher>>) => {
      const result = response.data
      if (result.success) {
        store.dispatch(Actions.COMPLETE_TEACHER_ACADEMIC_INFO, result.data).then(() => emit('success'))
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      completing.value = false
    })
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  .form-select {
    flex-grow: 1;
  }

  .form-control {
    flex-grow: 11;
  }
} 

</style>