import { AuthTeacher } from "./auth"
import { Certificate } from "./certificate"
import { Accent } from "./enums"

export const defaultAvatar: string = "/avatar.jpg"

export enum Level {
	BRONZE = 'BRONZE', // 铜牌
  SILVER = 'SILVER', // 银牌
  GOLD = 'GOLD', // 金牌
  PLATINUM = 'PLATINUM' // 白金
}

export interface Teacher {
  id: string
  level?: Level
  avatar?: string
  firstName?: string
  lastName?: string
  nickname?: string
  region?: string
  countryCode?: string
  mobile?: string
  email?: string
  zoneId?: string
  country?: string
  accent?: Accent
  show?: string
  introduction?: string
  certificates: Certificate[]
  basicInfoComplete?: boolean
  academicInfoComplete?: boolean
  complete?: boolean
  certified?: boolean
  vendor?: boolean

  base?: number
  addition?: number
  wage?: number

  balance?: number
  totalEarning?: number
  totalWithdraw?: number

  totalTeachingMinutes?: number
  rank?: number

  zoomAccount?: string
  zoomPassword?: string
}

export class TeacherImpl implements Teacher {
  id: string
  certificates: Certificate[]

  constructor(id: string) {
    this.id = id
    this.certificates = []
  }
}


export interface CompleteTeacherBasicInfo {
  id: string
  avatar: string
  firstName?: string
  lastName?: string
  nickname?: string
  country?: string
  accent?: Accent
  promoteCode?: string
}

export class CompleteTeacherBasicInfoImpl implements CompleteTeacherBasicInfo {
  id: string
  avatar: string

  constructor(id: string) {
    this.id = id
    this.avatar = defaultAvatar
  }
}

export interface CompleteTeacherAcademicInfo {
  id: string
  show: string
  certificates: Certificate[]
  introduction?: string
}

export class CompleteTeacherAcademicInfoImpl implements CompleteTeacherAcademicInfo {
  id: string
  show: string
  certificates: Certificate[]

  constructor(id: string) {
    this.id = id
    this.show  = ''
    this.certificates = []
  }
}

export interface CreateTeacherTeachingTimeSlots {
  id: string
}

export class CreateTeacherTeachingTimeSlotsImpl implements CreateTeacherTeachingTimeSlots {
  id: string
  
  constructor(id: string) {
    this.id = id
  }
}

export interface UpdateTeacherZoneId {
  id: string
  zoneId: string
}

export class UpdateTeacherZoneIdImpl implements UpdateTeacherZoneId {
  id: string
  zoneId: string

  constructor(id: string, zoneId: string) {
    this.id = id
    this.zoneId = zoneId
  }
}

export interface UpdateTeacherMobile {
  id: string
  region?: string
  countryCode?: string
  mobile?: string
  otp?: string

  refresh(teacher: Teacher): void
}

export class UpdateTeacherMobileImpl implements UpdateTeacherMobile {
  id: string
  region?: string
  countryCode?: string
  mobile?: string

  constructor(teacher: Teacher) {
    this.id = teacher.id
    this.region = teacher.region
    this.countryCode = teacher.countryCode
    this.mobile = teacher.mobile
  }

  refresh(teacher: Teacher): void {
    this.id = teacher.id
    this.region = teacher.region
    this.countryCode = teacher.countryCode
    this.mobile = teacher.mobile
  }
}

export interface UpdateTeacherEmail {
  id: string
  email: string
  otp?: string

  refresh(teacher: Teacher): void
}

export class UpdateTeacherEmailImpl implements UpdateTeacherEmail {
  id: string
  email: string

  constructor(teacher: Teacher) {
    this.id = teacher.id
    this.email = teacher.email!
  }

  refresh(teacher: Teacher): void {
    this.id = teacher.id
    this.email = teacher.email!
  }
}

export interface UpdateTeacherAvatar {
  id: string
  avatar: string
}

export class UpdateTeacherAvatarImpl implements UpdateTeacherAvatar {
  id: string
  avatar: string

  constructor(teacher: AuthTeacher) {
    this.id = teacher.id!
    this.avatar = teacher.avatar!
  }
}

export interface UpdateTeacherShow {
  id: string
  show: string

  refresh(teacher: Teacher): void
}

export class UpdateTeacherShowImpl implements UpdateTeacherShow {
  id: string
  show: string

  constructor(teacher: Teacher) {
    this.id = teacher.id
    this.show = teacher.show!
  }

  refresh(teacher: Teacher): void {
    this.id = teacher.id
    this.show = teacher.show!
  }
}


export interface UpdateTeacherCertificates {
  id: string
  certificates: Certificate[]

  refresh(teacher: Teacher): void
}

export class UpdateTeacherCertificatesImpl implements UpdateTeacherCertificates {
  id: string
  certificates: Certificate[]

  constructor(teacher: Teacher) {
    this.id = teacher.id
    this.certificates = teacher.certificates!
  }

  refresh(teacher: Teacher): void {
    this.id = teacher.id
    this.certificates = teacher.certificates!
  }
}


export interface UpdateTeacherInfo {
  id: string
  avatar: string
  firstName: string
  lastName: string
  nickname: string
  country: string
  accent: Accent
  introduction: string

  refresh(teacher: Teacher): void
}

export class UpdateTeacherInfoImpl implements UpdateTeacherInfo {
  id: string
  avatar: string
  firstName: string
  lastName: string
  nickname: string
  country: string
  accent: Accent
  introduction: string

  constructor(teacher: Teacher) {
    this.id = teacher.id
    this.avatar = teacher.avatar!
    this.firstName = teacher.firstName!
    this.lastName = teacher.lastName!
    this.nickname = teacher.nickname!
    this.country = teacher.country!
    this.accent = teacher.accent!
    this.introduction = teacher.introduction!
  }

  refresh(teacher: Teacher): void {
    this.id = teacher.id
    this.avatar = teacher.avatar!
    this.firstName = teacher.firstName!
    this.lastName = teacher.lastName!
    this.nickname = teacher.nickname!
    this.country = teacher.country!
    this.accent = teacher.accent!
    this.introduction = teacher.introduction!
  }
}
