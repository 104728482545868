<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header d-lg-flex justify-content-between align-items-center">
      <div class="mb-3 mb-lg-0">
        <h3 class="mb-0">Arrangement Details</h3>
      </div>
      <div>
        <router-link :to="{name: 'arrangements'}"><i class="bi-chevron-right"></i></router-link>
      </div>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <it-tabs :active-index="activeTabIndex">
        <template #tabs>
          <it-tab :index="1" @select="onTabSelect">Information</it-tab>
          <it-tab :index="2" @select="onTabSelect">Teacher Comment</it-tab>
          <it-tab :index="3" @select="onTabSelect">Student Review</it-tab>
        </template>

        <template #panes>
          <it-tab-pane :index="1">
            <it-info-group class="mt-3">
              <it-info-item label="Status" :value="ArrangementFilter.status(arrangement.status)"></it-info-item>
              <it-info-item v-if="arrangement.status === Status.CANCELED" label="Cancel Reason" :value="ArrangementFilter.cancelReason(arrangement.cancelReason)"></it-info-item>
              <it-info-item label="Start Time" :value="DateTimeFilter.datetime(arrangement.startDateTime)"></it-info-item>
              <it-info-item label="Course" :value="arrangement.courseFullName"></it-info-item>
              <it-info-item label="Lesson" :value="arrangement.lessonFullName"></it-info-item>
              <it-info-item label="Type" :value="CourseFilter.type(arrangement.courseType)"></it-info-item>
              <it-info-item label="Duration" :value="arrangement.duration" unit="Minitues"></it-info-item>
              <it-info-item label="Student" :value="arrangement.studentNickname"></it-info-item>
              <it-info-item label="Teacher" :value="arrangement.teacherNickname"></it-info-item>
            </it-info-group>
          </it-tab-pane>
          <it-tab-pane :index="2">
            <teacher-comment :arrangement="arrangement"></teacher-comment>
          </it-tab-pane>
          <it-tab-pane :index="3">
            <student-review :arrangement="arrangement"></student-review>
          </it-tab-pane>
        </template>
      </it-tabs>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import arrangementAPI from '../../api/arrangement'
import StudentReview from '../../components/schedule/StudentReview.vue'
import TeacherComment from '../../components/schedule/TeacherComment.vue'
import ItInfoGroup from '../../components/ui/info/ItInfoGroup.vue'
import ItInfoItem from '../../components/ui/info/ItInfoItem.vue'
import ItTab from '../../components/ui/tab/ItTab.vue'
import ItTabPane from '../../components/ui/tab/ItTabPane.vue'
import ItTabs from '../../components/ui/tab/ItTabs.vue'
import { ArrangementFilter } from '../../filters/arrangementFilter'
import { CourseFilter } from '../../filters/courseFilter'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { toast } from '../../injection'
import { Arrangement, ArrangementImpl, Status } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import { cloneFrom } from '../../utils/clone'

const toastRef = inject(toast)

const route = useRoute()

const arrangement = reactive<Arrangement>(new ArrangementImpl(route.params.id as string))
const doFindArrangement = function () {
  arrangementAPI.findById(route.params.id as string).then((response: AxiosResponse<Result<Arrangement>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(arrangement, result.data)
    } else {
      toastRef?.value?.error(result.message)
    }						
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

onMounted(() => {
  doFindArrangement()
})

const activeTabIndex = ref<number>(1)
const onTabSelect = function (index: number) {
  activeTabIndex.value = index
}
</script>
