<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">Profile</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <update-teacher-avatar></update-teacher-avatar>
      <hr class="my-5" />
      <update-teacher-info ref="updateTeacherInfoRef" :teacher="teacher"></update-teacher-info>
      <hr class="my-5" />
      <update-teacher-show ref="updateTeacherShowRef" :teacher="teacher"></update-teacher-show>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref } from 'vue'
import teacherAPI from '../../api/teacher'
import UpdateTeacherAvatar from '../../components/me/UpdateTeacherAvatar.vue'
import UpdateTeacherInfo from '../../components/me/UpdateTeacherInfo.vue'
import UpdateTeacherShow from '../../components/me/UpdateTeacherShow.vue'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Teacher, TeacherImpl } from '../../types/teacher'
import { cloneFrom } from '../../utils/clone'

const toastRef = inject(toast)

const store = useStore()

const teacher = reactive<Teacher>(new TeacherImpl(store.getters.id))

const updateTeacherInfoRef = ref()
const updateTeacherShowRef = ref()

onMounted(() => {
  doFindTeacher()
})

const doFindTeacher = function () {
  teacherAPI.findById(store.getters.id).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(teacher, result.data)
      updateTeacherInfoRef.value.refresh(teacher)
      updateTeacherShowRef.value.refresh(teacher)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}
</script>
