<template>
  <!-- Navbar -->
  <navbar></navbar>

  <!-- Page header-->
  <it-page-header title="Complete Information" description="Just fill the form and complete your information for teaching."></it-page-header>
  <!-- Page Content -->
  <div class="pb-12">
    <div class="container">
      <div class="bs-stepper">
        <div class="row">
          <it-stepper :activeStep="activeStep">
            <template #steps>
              <it-step :step="1" label="Basic Information"></it-step>
              <div class="bs-stepper-line"></div>
              <it-step :step="2" label="Academic Information"></it-step>
              <div class="bs-stepper-line"></div>
              <it-step :step="3" label="Finish"></it-step>
            </template>
            <template #panes>
              <it-step-pane :step="1">
                <complete-teacher-basic-info @success="onCompleteTeacherBasicInfoSuccess"></complete-teacher-basic-info>
              </it-step-pane>
              <it-step-pane :step="2">
                <complete-teacher-academic-info @success="onCompleteTeacherAcademicInfoSuccess"></complete-teacher-academic-info>
              </it-step-pane>
              <it-step-pane :step="3">
                <complete-teacher-info></complete-teacher-info>
              </it-step-pane>
            </template>
          </it-stepper>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <simple-footer></simple-footer>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import Navbar from '../components/Navbar.vue'
import SimpleFooter from '../components/SimpleFooter.vue'
import CompleteTeacherAcademicInfo from '../components/supplement/CompleteTeacherAcademicInfo.vue'
import CompleteTeacherBasicInfo from '../components/supplement/CompleteTeacherBasicInfo.vue'
import CompleteTeacherInfo from '../components/supplement/CompleteTeacherInfo.vue'
import ItPageHeader from '../components/ui/ItPageHeader.vue'
import ItStep from '../components/ui/wizard/ItStep.vue'
import ItStepPane from '../components/ui/wizard/ItStepPane.vue'
import ItStepper from '../components/ui/wizard/ItStepper.vue'
import { useStore } from '../store'

const store = useStore()
const router = useRouter()

const activeStep = ref<number>(1)

onMounted(() => {
  const teacher = store.state.teacher!
  if (!teacher.basicInfoComplete) {
    activeStep.value = 1
  } else if (!teacher.academicInfoComplete) {
    activeStep.value = 2
  } else if (!teacher.complete) {
    activeStep.value = 3
  } else {
    router.push({
      name: 'timeSlots'
    })
  }
})

const onCompleteTeacherBasicInfoSuccess = function() {
  activeStep.value = 2
}

const onCompleteTeacherAcademicInfoSuccess = function() {
    activeStep.value = 3
}
</script>
