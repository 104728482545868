import { Option, OptionImpl } from "."
import { Status } from "../../withdraw"

export class WithdrawStatusOptions {
	static options: Option[] = [
		new OptionImpl('All Status', Status.ALL),
    new OptionImpl('Requested', Status.REQUESTED),
		new OptionImpl('Rejected', Status.REJECTED),
		new OptionImpl('Approved', Status.APPROVED),
		new OptionImpl('Finished', Status.FINISHED),
	]
}