export interface Pagination {
	currentPage: number
	pageSize: number
	total: number

	reset (): void
}

export class PaginationImpl implements Pagination {
	currentPage = 1
	pageSize = 10
	total = 0

	reset (): void {
		this.currentPage = 1
		this.pageSize = 10
		this.total = 0
	}
}