import { Status, CancelReason } from "../types/arrangement"


export class ArrangementFilter {
  static status (status?: Status): string {
    switch (status) {
      case Status.BOOKED:
        return 'Booked'
      case Status.FINISHED:
        return 'Finished'
      case Status.CANCELED:
        return 'Canceled'
      default:
        return '' 
    }
  }

  static cancelReason (cancelReason?: CancelReason): string {
    switch (cancelReason) {
      case CancelReason.LEAVE_STUDENT:
        return 'Student Leave'
      case CancelReason.LEAVE_TEACHER:
        return 'Teacher Leave'
      case CancelReason.ABSENT_STUDENT:
        return 'Student Absent'
      case CancelReason.ABSENT_TEACHER:
        return 'Teacher Absent'
      case CancelReason.OTHERS:
        return 'Others'
      default:
        return '' 
    }
  }

  static recorded(recorded?: boolean): string {
    if (recorded == undefined) return ''
    else return recorded? 'finished' : 'processing'
  }

  static trial (trial?: boolean): string {
    if (trial == undefined) return ''
    else return trial? 'Trial' : 'Formal'
  }

  static demo (demo?: boolean): string {
    if (demo == undefined) return ''
    else return demo? 'Demo' : ''
  }
}