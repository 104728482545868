<template>
  <div>
    <h4 class="mb-4">Email</h4>
    
    <form ref="updateTeacherEmailForm" :class="{'was-validated': updateTeacherEmailFormValidated}" novalidate>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
      <!-- Email -->
      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <div class="input-group">
          <input ref="emailInput" v-model.trim="updateTeacherEmail.email" type="text" id="email" class="form-control" name="email" placeholder="Emaill address" required @input="onEmailInput()" />
          <it-button kind="primary" class="rounded-end" outline style="width: 120px;" :loading="sendingOtp" :disabled="disableOtpButton" @click="doSendUpdateEmailOtp()">{{ optButtonText }}</it-button>
          <div class="invalid-feedback" :class="{invalid: emailInputInvalid}">You must input valid emaill address.</div>
        </div>
      </div>
      <!-- OTP -->
      <div class="mb-3">
        <label for="otp" class="form-label">OTP</label>
        <input v-model.trim="updateTeacherEmail.otp" type="text" id="otp" class="form-control" name="otp" placeholder="One time password" required pattern="\d{6}" />
        <div class="invalid-feedback">You must input valid otp.</div>
      </div>
      <!-- Button -->
      <div class="d-grid">
        <it-button kind="primary" :loading="updating" @click="doUpdateTeacherEmail">Update Email</it-button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import Timer from 'tiny-timer'
import { inject, reactive, ref } from 'vue'
import emailAPI from '../../api/email'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { Teacher, UpdateTeacherEmail, UpdateTeacherEmailImpl } from '../../types/teacher'
import ItButton from '../ui/ItButton.vue'

const props = defineProps<{
  teacher: Teacher
}>()

const toastRef = inject(toast)

const message = ref<string>('')

const optButtonText = ref<string>('Get OTP')
const disableOtpButton = ref<boolean>(false)
const sendingOtp = ref<boolean>(false)

// timer
let countDown = 30
const timer = new Timer({ interval: 1000, stopwatch: false })
timer.on('tick', () => {
  optButtonText.value = Number(countDown--).toString() + 's'
})
timer.on('done', () => {
  optButtonText.value = 'Get OTP'
  disableOtpButton.value = false
  countDown = 30
})

const emailInput = ref<HTMLInputElement>()
const emailInputInvalid = ref<boolean>(false)
const onEmailInput = function() {
  emailInput.value?.setCustomValidity('')
  emailInputInvalid.value = false
}
const doSendUpdateEmailOtp = function() {
  if (emailInput.value?.checkValidity()) {
    sendingOtp.value = true
    disableOtpButton.value = true
    emailAPI.sendUpdateEmailOtp(updateTeacherEmail.email).then((response: AxiosResponse<Result<void>>) => {
      const result: Result<void> = response.data
      if (result.success) {
        toastRef?.value?.success('Send success')
        timer.start(countDown * 1000)
      } else {
        message.value = result.message
        disableOtpButton.value = false
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
      disableOtpButton.value = false
    }).finally(() => {
      sendingOtp.value = false
    })
  } else {
    emailInputInvalid.value = true
  }
}

const updateTeacherEmail = reactive<UpdateTeacherEmail>(new UpdateTeacherEmailImpl(props.teacher))
const updateTeacherEmailForm = ref<HTMLFormElement>()

const updating = ref<boolean>(false)
const updateTeacherEmailFormValidated = ref<boolean>(false)

const doUpdateTeacherEmail = function () {
  updateTeacherEmailFormValidated.value = true
  if (updateTeacherEmailForm.value?.checkValidity()) {
    updating.value = true
    teacherAPI.updateEmail(updateTeacherEmail).then((response: AxiosResponse<Result<Teacher>>) => {
      const result = response.data
      if (result.success) {
        updateTeacherEmailFormValidated.value = false
        disableOtpButton.value = false
        updateTeacherEmail.otp = ''
        toastRef?.value?.success('Success to update')
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      updating.value = false
    })
  }
}

const refresh = function (teacher: Teacher) {
  updateTeacherEmail.refresh(teacher)
}

defineExpose({
  refresh
})
</script>

<style lang="scss" scoped>

.invalid {
  display: block;
}

.input-group {
  .form-select {
    flex-grow: 1;
  }

  .form-control {
    flex-grow: 5;
  }
} 

</style>