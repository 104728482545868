<template>
  <!-- Zoom -->
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">Zoom Account</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <div class="alert alert-info" role="alert">Use the account below to start zoom meeting for class.</div>
      <div>
        <div class="mb-3">
          <label for="account" class="form-label">Username</label>
          <input id="account" class="form-control" v-model="teacher.zoomAccount" disabled readonly>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input id="password" class="form-control" v-model="teacher.zoomPassword" disabled readonly>
        </div>
      </div>
    </div>
  </div>

  <!-- Oxford Learners Bookshelf -->
  <div class="card mt-4">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">Oxford Learners Bookshelf Account</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <div class="alert alert-info" role="alert">Use the account below to login Oxford Learners Bookshelf.</div>
      <div>
        <div class="mb-3">
          <label for="account" class="form-label">Username</label>
          <input id="account" class="form-control" value="foresthuo@gmail.com" disabled readonly>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input id="password" class="form-control" value="?X&ER8?HsWBvtmA" disabled readonly>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive } from 'vue'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Teacher, TeacherImpl } from '../../types/teacher'
import { cloneFrom } from '../../utils/clone'

const toastRef = inject(toast)

const store = useStore()

const teacher = reactive<Teacher>(new TeacherImpl(store.getters.id))

onMounted(() => {
  doFindTeacher()
})

const doFindTeacher = function () {
  teacherAPI.findById(store.getters.id).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(teacher, result.data)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}
</script>