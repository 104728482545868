<template>
  <div class="card">
    <div class="card-header d-lg-flex align-items-center justify-content-between">
      <h3 class="mb-0">Arrangements</h3>
      
      <div class="d-flex justify-content-between">
        <select class="form-select" v-model="filters.status" @change="onStatusChange" style="width: 200px;">
          <option v-for="(option, index) in ArrangementStatusOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
        </select>
        <select class="form-select ms-3" v-model="filters.cancelReason" @change="onCancelReasonChange" style="width: 200px;">
          <option v-for="(option, index) in ArrangementCancelReasonOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
        </select>
      </div>
    </div>
    
    <div class="table-responsive border-0">
      <table class="table mb-0">
        <thead class="table-light">
          <tr>
            <th scope="col" class="border-0">#</th>
            <th scope="col" class="border-0">Status</th>
            <th scope="col" class="border-0">Arrange</th>
            <th scope="col" class="border-0">lesson</th>
            <th scope="col" class="border-0">Comment</th>
            <th scope="col" class="border-0">Review</th>
            <th scope="col" class="border-0">Actions</th>
          </tr>
        </thead>
        <tbody v-if="arrangements && arrangements?.length > 0">
          <tr v-for="(arrangement, index) in arrangements" :key="arrangement.id">
            <th scope="row" class="align-middle border-top-0">{{ IndexFilter.index(pagination.currentPage, pagination.pageSize, index) }}</th>
            <td class="align-middle border-top-0">
              <div>{{ ArrangementFilter.status(arrangement.status) }}</div>
              <div>{{ ArrangementFilter.cancelReason(arrangement.cancelReason) }}</div>
            </td>
            <td class="align-middle border-top-0">
              <div>{{ arrangement.studentNickname }}</div>
              <div>{{ DateTimeFilter.date(arrangement.startDateTime) }}</div>
              <div>{{ DateTimeFilter.time(arrangement.startDateTime) }}</div>
            </td>
            <td class="align-middle border-top-0">
              <div>{{ arrangement.courseFullName }}</div>
              <div>{{ arrangement.lessonFullName }}</div>
              <div>{{ CourseFilter.brief(arrangement.courseType, arrangement.trial, arrangement.courseLessonDuration) }}</div>
              <div>{{ ArrangementFilter.demo(arrangement.demo) }}</div>
            </td>
            <td class="align-middle border-top-0">
              <div><it-rate v-model="arrangement.star" disabled /></div>
            </td>
            <td class="align-middle border-top-0">
              <div><it-rate v-model="arrangement.rank" disabled /></div>
            </td>
            <td class="text-muted align-middle border-top-0 text-nowrap" width="120" style="padding: 0.75rem 1.5rem 0.75rem 0;">
              <div>
                <it-button kind="link" @click="doViewArrangement(arrangement)">Details</it-button>
              </div>
              <div>
                <it-button kind="link" :disabled="arrangement.status !== Status.BOOKED" @click="doFinishArrangement(arrangement)">Finish Class</it-button>
              </div>
            </td>
          </tr>
        </tbody>
        <caption v-else style="text-align: center;">No Arrangement</caption>
      </table>

      <it-pagination class="my-2 mx-4 float-end" :total="pagination.total" :page-size="pagination.pageSize" v-model:current-page="pagination.currentPage"></it-pagination>
    </div>
  </div>

  <finish-arrangement-dialog id="finishArrangementDialog" :arrangement-id="arrangementId" :arrangementLessonType="arrangementLessonType" @success="onFinishArrangementSuccess"></finish-arrangement-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { Modal } from 'bootstrap'
import { inject, onMounted, reactive, ref, watch } from 'vue'
import arrangementAPI from '../../api/arrangement'
import FinishArrangementDialog from '../../components/schedule/FinishArrangementDialog.vue'
import ItButton from '../../components/ui/ItButton.vue'
import ItPagination from '../../components/ui/ItPagination.vue'
import ItRate from '../../components/ui/ItRate.vue'
import { ArrangementFilter } from '../../filters/arrangementFilter'
import { CourseFilter } from '../../filters/courseFilter'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { IndexFilter } from '../../filters/indexFilter'
import { toast } from '../../injection'
import router from '../../router'
import { useStore } from '../../store'
import { Arrangement, Status } from '../../types/arrangement'
import { ArrangementCancelReasonOptions, ArrangementStatusOptions } from '../../types/common/option/arrangementOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { ArrangementFilters, ArrangementFiltersImpl } from '../../types/common/query/filters/arrangementFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { ArrangementSearcherImpl } from '../../types/common/query/searcher/arrangementSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Type as LessonType } from '../../types/lesson'

const toastRef = inject(toast)

const store = useStore()

const loading = ref<boolean>(false)

const onStatusChange = function () {
  doListArrangement()
}

const onCancelReasonChange = function () {
  doListArrangement()
}

const arrangements = ref<Arrangement[]>()

let finishArrangementDialog: Modal | undefined = undefined

onMounted(() => {
  doListArrangement()

  finishArrangementDialog = new Modal(document.getElementById('finishArrangementDialog')!)
})

// Query arrangement
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<ArrangementFilters>(new ArrangementFiltersImpl(store.getters.id))
const searcher = reactive<Searcher>(new ArrangementSearcherImpl())
const sorts = [new SortImpl('startDateTime', Direction.DESC)]

const doListArrangement = function () {
  const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
  loading.value = true
  arrangementAPI.list(query).then((response: AxiosResponse<Result<ListObject<Arrangement>>>) => {
    const result = response.data
    if (result.success) {
      const listObject = result.data
      arrangements.value = listObject.objects
      pagination.total = listObject.total
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// View arrangement
const doViewArrangement = function (arrangement: Arrangement) {
  router.push({
    name: 'arrangement', 
    params: {id: arrangement.id}
  })
}

const arrangementId = ref<string>('')
const arrangementLessonType = ref<LessonType>(LessonType.LECTURE)

// Finish arrangement
const doFinishArrangement = function (arrangement: Arrangement) {
  arrangementId.value = arrangement.id
  arrangementLessonType.value = arrangement.lessonType!
  finishArrangementDialog?.show()
}
const onFinishArrangementSuccess = function () {
  finishArrangementDialog?.hide()
  doListArrangement()
}

watch(() => pagination.currentPage, () => {
  doListArrangement()
})
</script>

<style scoped>

.time {
	display: flex;
	justify-content: space-between;
}

.table > :not(caption) > * > * {
  padding: 0.75rem;
}
</style>