<template>
  <div>
    <div class="mb-3 d-flex align-items-center justify-content-between">
      <h4>Show</h4>
      <it-button kind="secondary" outline size="sm" @click="doEditTeacherShow">Change</it-button>
    </div>
    
    <teacher-show-uploader v-model:show="updateTeacherShow.show" v-model:edit="edit" @success="doUpdateTeacherShow"></teacher-show-uploader>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, reactive, ref } from 'vue'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { Teacher, UpdateTeacherShow, UpdateTeacherShowImpl } from '../../types/teacher'
import ItButton from '../ui/ItButton.vue'
import TeacherShowUploader from './TeacherShowUploader.vue'

const props = defineProps<{
  teacher: Teacher
}>()

const toastRef = inject(toast)

const edit = ref<boolean>(false)

const updateTeacherShow = reactive<UpdateTeacherShow>(new UpdateTeacherShowImpl(props.teacher))

const doUpdateTeacherShow = function () {
  teacherAPI.updateShow(updateTeacherShow).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      edit.value = false
      toastRef?.value?.success('Success to update')
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const doEditTeacherShow = function () {
  edit.value = !edit.value
}

const refresh = function (teacher: Teacher) {
  updateTeacherShow.refresh(teacher)
}

defineExpose({
  refresh
})
</script>