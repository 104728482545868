import { Result } from "../types/common/result"
import service from "./api-service"

const api = '/sms'

const smsAPI = {
  sendAuthOtp (countryCode: string, mobile: string) {
    return service.get<Result<void>>(api + '/sendAuthOtp', { 
      params: {
        countryCode: countryCode,
        mobile: mobile
      }
    })
  },

  sendUpdateMobileOtp (countryCode: string, mobile: string) {
    return service.get<Result<void>>(api + '/sendUpdateMobileOtp', {
      params: {
        countryCode: countryCode,
        mobile: mobile
      }
    })
  },
}

export default smsAPI