<template>
  <div class="row">
    <div class="col-lg-4 col-md-12 col-12">
      <!-- Card -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="h1 fw-bold mb-0 mt-4 lh-1">$ {{ NumberFilter.money(teacher.wage) }}</h2>
          <p>Hourly Rate</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-12">
      <!-- Card body -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="h1 fw-bold mb-0 mt-4 lh-1">$ {{ NumberFilter.money(teacher.base) }}</h2>
          <p>Basic Rate</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-12">
      <!-- Card body -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="h1 fw-bold mb-0 mt-4 lh-1">$ {{ NumberFilter.money(teacher.addition) }}</h2>
          <p>Bonus Rate</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 col-md-12 col-12">
      <!-- Card -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="fw-bold mb-0 mt-4 lh-1">{{ TeacherFilter.level(teacher.level) }}</h2>
          <p>Teacher Level</p>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-12 col-12">
      <!-- Card -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="fw-bold mb-0 mt-4 lh-1">{{ TeacherFilter.certificated(teacher.certified) }}</h2>
          <p>Teacher Certification</p>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-12 col-12">
      <!-- Card body -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="fw-bold mb-0 mt-4 lh-1">{{ teacher.totalTeachingMinutes }}</h2>
          <p>Teaching Minutes</p>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-12 col-12">
      <!-- Card body -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="fw-bold mb-0 mt-4 lh-1">{{ NumberFilter.rank(teacher.rank) }}</h2>
          <p>Student Review</p>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h3 class="mb-0">Explaination</h3>
    </div>
    <div class="card-body">
      <p class="card-text">Your total compensation rate is the sum of basic rate of $20 per hour, and bonus rate, which is calculated upon the following criteria.</p>

      <p class="card-text"><strong>Hourly Rate = Basic Rate + Bonus Rate</strong></p>

      <table class="table mb-0">
        <thead class="table-light">
          <tr>
            <th scope="col" class="border-0">Additional Wage</th>
            <th scope="col" class="border-0">Condition</th>
            <th scope="col" class="border-0">Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="align-middle border-top-0">
              +2
            </td>
            <td class="align-middle border-top-0">
              Certified
            </td>
            <td class="align-middle border-top-0">
              TESOL / TEFL Certificates
            </td>
          </tr>
          <tr>
            <td class="align-middle border-top-0">
              +0
            </td>
            <td class="align-middle border-top-0">
              Bronze Level
            </td>
            <td class="align-middle border-top-0">
              Registered
            </td>
          </tr>
          <tr>
            <td class="align-middle border-top-0">
              +2
            </td>
            <td class="align-middle border-top-0">
              Sliver Level
            </td>
            <td class="align-middle border-top-0">
              Total Teaching Minutes >= 20000 And Review Rank >= 4.0
            </td>
          </tr>
          <tr>
            <td class="align-middle border-top-0">
              +4
            </td>
            <td class="align-middle border-top-0">
              Gold Level
            </td>
            <td class="align-middle border-top-0">
              Total Teaching Minutes >= 40000 And Review Rank >= 4.0
            </td>
          </tr>
          <tr>
            <td class="align-middle border-top-0">
              +6
            </td>
            <td class="align-middle border-top-0">
              Platinum Level
            </td>
            <td class="align-middle border-top-0">
              Total Teaching Minutes >= 60000 And Review Rank >= 4.5
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive } from 'vue'
import teacherAPI from '../../api/teacher'
import { NumberFilter } from '../../filters/numberFilter'
import { TeacherFilter } from '../../filters/teacherFilter'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Teacher, TeacherImpl } from '../../types/teacher'
import { cloneFrom } from '../../utils/clone'

const toastRef = inject(toast)

const store = useStore()

const teacher = reactive<Teacher>(new TeacherImpl(store.getters.id))
const doFindTeacher = function () {
    teacherAPI.findById(store.getters.id).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(teacher, result.data)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

onMounted(() => {
  doFindTeacher()
})
</script>