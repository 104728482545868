import { DayOfWeek } from "./enums";

export enum Status {
  CREATED = 'CREATED', // 已报名
  STARTED = 'STARTED', // 已开课
  SUSPENDED = 'SUSPENDED', // 已暂停
  FINISHED = 'FINISHED', // 已完成
  CANCELED = 'CANCELED' // 已取消
}

export enum SuspendReason {
  LEAVE = 'LEAVE', // 请假
  INSUFFICIENT = 'INSUFFICIENT', // 账户余额不足
}

export interface Arrange {
  teacherId: string
  dayOfWeek: DayOfWeek
  index: number
  startTime: Date
  endTime: Date
}

export interface Enrollment {
  id: string
  status?: Status
  suspendReason?: SuspendReason

  parentId?: string
  parentNickname?: string
  
  studentId?: string
  studentNickname?: string

  serieId?: string
  serieName?: string

  courseId?: string
  courseName?: string
  courseFullName?: string
  
  latestFinishedLessonId?: string
  latestFinishedLessonNumber?: number
  latestFinishedLessonName?: string
  latestFinishedLessonFullName?: string

  latestBookedLessonId?: string
  latestBookedLessonNumber?: number
  latestBookedLessonName?: string
  latestBookedLessonFullName?: string

  createdDateTime?: Date
  startedDateTime?: Date
  finishedDateTime?: Date
  canceledDateTime?: Date

  arranges?: Arrange[]
}

export class EnrollmentImpl implements Enrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}