<template>
  <div v-if="active" class="tab-pane fade show" :class="{active: active}" role="tabpanel">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance } from 'vue';

const props = defineProps<{
  index: number
}>()

const instance = getCurrentInstance()
    
const active = computed(() => {
return props.index == instance?.parent?.props.activeIndex
})
</script>