import { Result } from "../types/common/result"
import { DetailModule } from "../types/module"
import service from "./api-service"

const api = '/module'

const moduleAPI = {
  findDetailByCourseId (courseId: string) {
    return service.get<Result<DetailModule[]>>(api + '/findDetailByCourseId', { 
      params: {
        courseId: courseId
      }
    })
  }
}

export default moduleAPI