<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-12">
      <h3 class="mb-2">{{ serie.name }} <span class="tag">{{ SerieFilter.major(serie.major) }}</span></h3>
    </div>
  </div>
  <div class="row row-cols-3 mb-5">
    <div v-for="course in courses" :key="course.id" class="col mt-4">
      <course-card :course="course"></course-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, ref } from 'vue'
import courseAPI from '../../api/course'
import { SerieFilter } from '../../filters/serieFilter'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { SerieCourse } from '../../types/course'
import { Serie } from '../../types/serie'
import CourseCard from '../course/CourseCard.vue'

const props = defineProps<{
  serie: Serie
}>()

const toastRef = inject(toast)

const courses = ref<SerieCourse[]>()
const doFindCourse = function () {
  courseAPI.findBySerieId(props.serie.id).then((response: AxiosResponse<Result<SerieCourse[]>>) => {
    const result = response.data
    if (result.success) {
      courses.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }						
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

onMounted(() => {
  doFindCourse()
})
</script>