<template>
  <div class="footer">
    <div class="container">
      <div class="row align-items-center g-0 border-top py-2">
        <!-- Desc -->
        <div class="col-md-6 col-12 text-center text-md-start">
          <span>© 2021 {{ company }}. All Rights Reserved.</span>
        </div>
        <!-- Links -->
        <div class="col-12 col-md-6">
          <nav class="nav nav-footer justify-content-center justify-content-md-end">
            <a class="nav-link" href="#">Privacy</a>
            <a class="nav-link" href="#">Terms</a>
            <a class="nav-link" href="#">Feedback</a>
            <a class="nav-link" href="#">Support</a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const company = import.meta.env.VITE_APP_COMPANY
</script>