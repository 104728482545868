<template>
  <nav class="navbar navbar-expand-md navbar-light shadow-sm mb-4 mb-lg-0 sidenav">    
    <div class="navbar-nav flex-column">
      <span class="navbar-header">Course</span>
      <!-- List -->
      <ul class="list-unstyled ms-n2 mb-4">
        <!-- Courses -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'courses'}"><i class="bi-easel-fill nav-icon"></i>Courses</router-link></li>
      </ul>

      <span class="navbar-header">Teaching</span>
      <ul class="list-unstyled ms-n2 mb-4">
        <!-- Schedule -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'schedule'}"><i class="bi-calendar2-week-fill nav-icon"></i>Schedule</router-link></li>
        <!-- Arrangements -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'arrangements'}"><i class="bi-calendar2-check-fill nav-icon"></i>Arrangements</router-link></li>
        <!-- Classroom -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'classroom'}"><i class="bi-aspect-ratio-fill nav-icon"></i>Classroom</router-link></li>
      </ul>

      <span v-if="!teacher.vendor" class="navbar-header">FINANCE</span>
      <ul v-if="!teacher.vendor" class="list-unstyled ms-n2 mb-4">
        <!-- Salary -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'salary'}"><i class="bi-credit-card-2-back-fill nav-icon"></i>My Pay</router-link></li>
        <!-- Withdraw -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'withdraw'}"><i class="bi-credit-card-fill nav-icon"></i>Withdraw</router-link></li>
        <!-- Statements -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'statements'}"><i class="bi-clipboard-fill nav-icon"></i>History</router-link></li>
      </ul>
      
      <span class="navbar-header">Account</span>
      <!-- List -->
      <ul class="list-unstyled ms-n2 mb-0">
        <!-- Profile -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'profile'}"><i class="bi-person-fill nav-icon"></i>Profile</router-link></li>
        <!-- Zoom Account -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'accounts'}"><i class="bi-person-lines-fill nav-icon"></i>Accounts</router-link></li>
        <!-- Certificates -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'certificates'}"><i class="bi-postcard-fill nav-icon"></i>Certificates</router-link></li>
        <!-- Security -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'security'}"><i class="bi-key-fill nav-icon"></i>Security</router-link></li>
        <!-- Linked Accounts -->
        <!-- <li class="nav-item"><router-link class="nav-link" :to="{name: 'linkedAccounts'}"><i class="bi-person-plus-fill nav-icon"></i>Linked Accounts</router-link></li> -->
        <!-- Time Slots -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'timeSlots'}"><i class="bi-calendar2-heart-fill nav-icon"></i>Time Slots</router-link></li>
        <!-- Settings -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'settings'}"><i class="bi-gear-fill nav-icon"></i>Settings</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { useStore } from '../store';

const store = useStore()
const teacher = store.state.teacher
</script>
