<template>
  <div class="card">
    <div class="card-header d-lg-flex align-items-center justify-content-between">
      <h3 class="mb-0">Earning History</h3>
    </div>
    
    <div class="table-responsive border-0">
      <table class="table mb-0">
        <thead class="table-light">
          <tr>
            <th scope="col" class="border-0">#</th>
            <th scope="col" class="border-0">Date</th>
            <th scope="col" class="border-0">Amount</th>
            <th scope="col" class="border-0">Comment</th>
          </tr>
        </thead>
        <tbody v-if="earnings && earnings?.length > 0">
          <tr v-for="(earning, index) in earnings" :key="earning.id">
						<th scope="row" class="align-middle border-top-0">{{ IndexFilter.index(pagination.currentPage, pagination.pageSize, index) }}</th>
            <td class="align-middle border-top-0">
							<div>{{ DateTimeFilter.date(earning.dateTime) }}</div>
							<div>{{ DateTimeFilter.time(earning.dateTime) }}</div>
						</td>
            <td class="align-middle border-top-0">{{ NumberFilter.money(earning.value) }}</td>
            <td class="align-middle border-top-0">{{ earning.remark }}</td>
          </tr>
        </tbody>
        <caption v-else style="text-align: center;">No Earning</caption>
      </table>

      <it-pagination class="my-2 mx-4 float-end" :total="pagination.total" :page-size="pagination.pageSize" v-model:current-page="pagination.currentPage"></it-pagination>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref, watch } from 'vue'
import earningAPI from '../../api/earning'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { IndexFilter } from '../../filters/indexFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { toast } from '../../injection'
import { Query, QueryImpl } from '../../types/common/query'
import { EarningFilters, EarningFiltersImpl } from '../../types/common/query/filters/earningFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { EarningSearcherImpl } from '../../types/common/query/searcher/earningSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Earning } from '../../types/earning'
import ItPagination from '../ui/ItPagination.vue'

const props = defineProps<{
  teacherId: string
}>()

const toastRef = inject(toast)

const loading = ref<boolean>(false)

const earnings = ref<Earning[]>()

onMounted(() => {
	doListEarning()
})

// Query earning
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<EarningFilters>(new EarningFiltersImpl(props.teacherId))
const searcher = reactive<Searcher>(new EarningSearcherImpl())
const sorts = [new SortImpl('dateTime', Direction.DESC)]

const doListEarning = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	earningAPI.list(query).then((response: AxiosResponse<Result<ListObject<Earning>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			earnings.value = listObject.objects
			pagination.total = listObject.total
		} else {
			toastRef?.value?.error(result.message)
		}
	}).catch((error: AxiosError) => {
		toastRef?.value?.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListEarning()
})
</script>

<style scoped>

.time {
	display: flex;
	justify-content: space-between;
}
</style>