import { Result } from "../types/common/result";
import service from "./api-service";

const api = '/email'

const emailAPI = {
  sendAuthOtp (email: string) {
    return service.get<Result<void>>(api + '/sendAuthOtp', { 
      params: {
        email: email
      }
    })
  },

  sendUpdateEmailOtp (email: string) {
    return service.get<Result<void>>(api + '/sendUpdateEmailOtp', { 
      params: {
        email: email
      }
    })
  },
}

export default emailAPI