export class NumberFilter {
  
  static money (number?: number): string {
    if (number) return number.toFixed(2)
    else if (number == 0) return '0.00'
    else return ''
  }

  static rank (number?: number): string {
    if (number) return number.toFixed(1)
    else if (number == 0) return '0.0'
    else return ''
  }
}