<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header d-lg-flex justify-content-between align-items-center">
      <h3 class="mb-0">{{ course.fullName }}</h3>
      <div>
        <router-link :to="{name: 'courses'}"><i class="bi-chevron-right"></i></router-link>
      </div>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <it-tabs :active-index="activeTabIndex">
        <template #tabs>
          <it-tab :index="1" @select="onTabSelect">Basic Info</it-tab>
          <it-tab :index="2" @select="onTabSelect">Lessons</it-tab>
          <it-tab :index="3" @select="onTabSelect">Teaching Guide</it-tab>
        </template>

        <template #panes>
          <it-tab-pane :index="1">
            <it-info-group class="mt-3">
              <it-info-item label="Serie" :value="course.serieName"></it-info-item>
              <it-info-item label="Type" :value="CourseFilter.type(course.type)"></it-info-item>
              <it-info-item label="Lesson Duration" :value="course.lessonDuration" unit="Minutes"></it-info-item>
              <it-info-item label="Lesson Count" :value="course.lessonCount" unit="Lessons"></it-info-item>
            </it-info-group>
          </it-tab-pane>
          <it-tab-pane :index="2">
            <module-lessons class="mt-3" :course-id="course.id"></module-lessons>
          </it-tab-pane>
          <it-tab-pane :index="3">
            <div class="mt-3" v-html="course.guide"></div>
          </it-tab-pane>
        </template>
      </it-tabs>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import courseAPI from '../../api/course'
import ModuleLessons from '../../components/course/ModuleLessons.vue'
import ItInfoGroup from '../../components/ui/info/ItInfoGroup.vue'
import ItInfoItem from '../../components/ui/info/ItInfoItem.vue'
import ItTab from '../../components/ui/tab/ItTab.vue'
import ItTabPane from '../../components/ui/tab/ItTabPane.vue'
import ItTabs from '../../components/ui/tab/ItTabs.vue'
import { CourseFilter } from '../../filters/courseFilter'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { Course, CourseImpl } from '../../types/course'
import { cloneFrom } from '../../utils/clone'

const toastRef = inject(toast)

const route = useRoute()
const router = useRouter()

const course = reactive<Course>(new CourseImpl(route.params.id as string))
const doFindCourse = function () {
  courseAPI.findById(route.params.id as string).then((response: AxiosResponse<Result<Course>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(course, result.data)
    } else {
      toastRef?.value?.error(result.message)
    }						
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

onMounted(() => {
  doFindCourse()
})

const activeTabIndex = ref<number>(1)
const onTabSelect = function (index: number) {
  activeTabIndex.value = index
}
</script>