import { PhoneCountryCode } from "../types/mobile"

export function getCountryCodeFromRegion(phoneCountryCodes?: PhoneCountryCode[], region?: string): string | undefined {
  const filteredPhoneCountryCodes = phoneCountryCodes?.filter(phoneCountryCode => {
    return phoneCountryCode.region === region
  })

  if (filteredPhoneCountryCodes && filteredPhoneCountryCodes.length > 0) {
    return filteredPhoneCountryCodes[0].code
  } else {
    return undefined
  }
}