<template>
  <div>
    <h4 class="mb-4">Basic Info</h4>

    <form ref="updateTeacherInfoForm" class="row g-3" :class="{'was-validated': updateTeacherInfoFormValidated}" novalidate>
      <!-- Alert -->
      <div v-if="message" class="col-12 alert alert-danger" role="alert">{{ message }}</div>
      <!-- Name -->
      <div class="col-md-6">
        <label for="first-name" class="form-label">First Name</label>
        <input v-model.trim="updateTeacherInfo.firstName" type="text" id="first-name" class="form-control" name="first-name" required />
        <div class="invalid-feedback">You must input valid name.</div>
      </div>
      <div class="col-md-6">
        <label for="last-name" class="form-label">Last Name</label>
        <input v-model.trim="updateTeacherInfo.lastName" type="text" id="last-name" class="form-control" name="last-name" required />
        <div class="invalid-feedback">You must input valid name.</div>
      </div>
      <!-- Nickname -->
      <div class="col-12">
        <label for="nickname" class="form-label">Nickname</label>
        <input v-model.trim="updateTeacherInfo.nickname" type="text" id="nickname" class="form-control" name="nickname" required />
        <div class="invalid-feedback">You must input valid nickname.</div>
      </div>
      <!-- Country -->
      <div class="col-md-6">
        <label for="country" class="form-label">Country</label>
        <select v-model="updateTeacherInfo.country" id="country" class="form-select" name="country" required>
          <option v-for="country in countries" :key="country.code" :value="country.code">{{ country.name }}</option>
        </select>
        <div class="invalid-feedback">You must select your country.</div>
      </div>
      <!-- Accent -->
      <div class="col-md-6">
        <label for="accent" class="form-label">Accent</label>
        <select v-model="updateTeacherInfo.accent" id="accent" class="form-select" name="accent" required>
          <option v-for="(option, index) in AccentOptions.options" :key="index" :value="option.value">{{ option.label }}</option>
        </select>
        <div class="invalid-feedback">You must select your accent.</div>
      </div>
      <!-- Introduction -->
      <div class="col-12">
        <label for="introduction" class="form-label">Introduction</label>
        <textarea rows="5" v-model.trim="updateTeacherInfo.introduction" id="introduction" class="form-control" name="introduction" required />
        <div class="invalid-feedback">You must input valid introduction.</div>
      </div>
      <!-- Button -->
      <div class="d-grid">
        <it-button kind="primary" :loading="updating" @click="doUpdateTeacherInfo">Update Basic Info</it-button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref } from 'vue'
import countryAPI from '../../api/country'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { AccentOptions } from '../../types/common/option/accentOptions'
import { Result } from '../../types/common/result'
import { Country } from '../../types/country'
import { Teacher, UpdateTeacherInfo, UpdateTeacherInfoImpl } from '../../types/teacher'
import ItButton from '../ui/ItButton.vue'

const props = defineProps<{
  teacher: Teacher
}>()

const toastRef = inject(toast)

onMounted(() => {
  doFindAllCountries()
})

const message = ref<string>('')

// country
const countries = ref<Country[]>()
const doFindAllCountries = function () {
  countryAPI.findAll().then((response: AxiosResponse<Result<Country[]>>) => {
    const result = response.data
    if (result.success) {
      countries.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const updateTeacherInfo = reactive<UpdateTeacherInfo>(new UpdateTeacherInfoImpl(props.teacher))
const updateTeacherInfoForm = ref<HTMLFormElement>()

const updating = ref<boolean>(false)
const updateTeacherInfoFormValidated = ref<boolean>(false)

const doUpdateTeacherInfo = function () {
  updateTeacherInfoFormValidated.value = true
  if (updateTeacherInfoForm.value?.checkValidity()) {
    updating.value = true
    teacherAPI.updateInfo(updateTeacherInfo).then((response: AxiosResponse<Result<Teacher>>) => {
      const result = response.data
      if (result.success) {
        updateTeacherInfoFormValidated.value = false
        toastRef?.value?.success('Success to update')
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      updating.value = false
    })
  }
}

const refresh = function (teacher: Teacher) {
  updateTeacherInfo.refresh(teacher)
}

defineExpose({
  refresh
})
</script>
