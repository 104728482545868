import { Type } from "../types/course"

export class CourseFilter {

  static brief (type?: Type, trial?: boolean, duration?: number): string {
    return this.type(type) + ' - ' + this.trial(trial) + ' - ' + this.duration(duration)
  }

  static type (type?: Type): string {
    switch (type) {
      case Type.VIP:
        return '1v1'
      case Type.GROUP:
        return 'Group'
      default:
        return '' 
    }
  }

  static trial (trial?: boolean): string {
    if (trial == undefined) return ''
    else return trial? 'Trial' : 'Formal'
  }

  static ready (ready?: boolean): string {
    if (ready == undefined) return ''
    else return ready? '' : 'Preparing'
  }

  static duration (duration?: number): string {
    return duration + ' min'
  } 
}