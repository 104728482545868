<template>
  <div>
    <div v-if="editShow">
      <label for="show" class="form-label">Video Show</label>
      <div class="input-group">
        <input type="file" id="show" class="form-control" name="show" accept="video/*" @change="beforeUploadTeacherShow" required>
        <it-loading :loading="uploading"></it-loading>
      </div>
      <small class="mt-2 d-block">
        Upload your show video here. It must meet our show video quality standards.
        Important guidelines: mpeg, mp4, avi, or wmv format; less than 50M.
      </small>
      <small class="mt-2 error" v-if="message">{{ message }}</small>
    </div>
    
    <video class="mt-2" style="width: 100%;" v-if="showURL" :src="showURL" controls preload="auto">
      Your browser does not support video tag
    </video>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, ref } from 'vue'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { useModelWrapper } from '../../utils/modelWrapper'
import ItLoading from '../ui/ItLoading.vue'

export interface Props {
  show?: string,
  edit: boolean
}

const props = withDefaults(defineProps<Props>(), {
  edit: true
})

const emit = defineEmits<{
  (e: 'update:show'): void,
  (e: 'update:edit'): void,
  (e: 'success', url: string): void
}>()

const toastRef = inject(toast)

const showURL = useModelWrapper(props, emit, 'show')
const editShow = useModelWrapper(props, emit, 'edit')

const uploading = ref<boolean>(false)
const message = ref<string>('')

const beforeUploadTeacherShow = function (event: Event) {
  const target = event.target as HTMLInputElement
  if (target.files && target.files[0]) {
    const file = target.files[0]
    // 判断视频大小
    if (file.size > 1024 * 1024 * 50) {
      message.value = 'Please upload video which size is less than 50M'
    } else {
      doUploadTeacherShow(file)
    }
  }
}

const doUploadTeacherShow = function (show: File) {
  uploading.value = true
  teacherAPI.uploadShow(show).then((response: AxiosResponse<Result<string>>) => {
    const result = response.data
    if (result.success) {
      showURL.value = result.data
      emit('success', result.data)
    } else {
      message.value = result.message
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    uploading.value = false
  })
}

const doEditTeacherShow = function () {
  editShow.value = true
}

defineExpose({
  doEditTeacherShow
})
</script>
