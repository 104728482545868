export enum Name {
  TESOL = 'TESOL',
  TEFL = 'TEFL'
}

export interface Certificate {
  name?: Name
  photo?: string
}

export class CertificateImpl implements Certificate {

}

