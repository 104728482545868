<template>
  <div class="avatar" :class="[sizeClass]">
    <img alt="avatar" :src="src" class="rounded-circle" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type Size = 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface Props {
  src?: string,
  size?: Size
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md'
})

const sizeClass = computed(() => {
  return 'avatar-' + props.size
})
</script>
