import { Type as CourseType } from "./course"
import { Type as LessonType } from "./lesson"

export enum Status {
  ALL = '', // 全部状态
  BOOKED = 'BOOKED', // 已预约
  FINISHED = 'FINISHED', // 已完成
  CANCELED = 'CANCELED', // 已取消
}

export enum CancelReason {
  ALL = '', // 全部原因
  LEAVE_STUDENT = 'LEAVE_STUDENT', // 学生请假（不扣课时）
  LEAVE_TEACHER = 'LEAVE_TEACHER', // 老师请假（不发工资）

  ABSENT_STUDENT = 'ABSENT_STUDENT', // 学生缺席（扣课时）
  ABSENT_TEACHER = 'ABSENT_TEACHER', // 老师缺席（不发工资，罚课时）

  OTHERS = 'OTHERS', // 其他（学生不扣课时，老师不发工资）
}

export interface Arrangement {
  id: string
  status?: Status
  cancelReason?: CancelReason
  cancelRemark?: string

  startDateTime?: Date
  duration?: number

  bookedDateTime?: Date
  finishedDateTime?: Date
  canceledDateTime?: Date

  rank?: number
  feedback?: string

  star?: number
  remark?: string

  trial?: boolean
  demo?: boolean

  teacherId?: string
  teacherNickname?: string

  studentId?: string
  studentNickname?: string

  parentId?: string
  parentNickname?: string

  lessonId?: string
  lessonNumber?: number
  lessonName?: string
  lessonFullName?: string
  lessonSlide?: string

  lessonType?: LessonType
  lessonTotalTestScore?: number

  courseId?: string
  courseName?: string
  courseFullName?: string
  courseType?: CourseType
  courseLessonDuration?: number

  meetingId?: number
  startUrl?: string
  joinUrl?: string
  meetingPassword?: string
  recorded?: boolean
}

export class ArrangementImpl implements Arrangement {
  id: string

  constructor(id: string) {
    this.id = id
  }

}

export interface CreateArrangementMeeting {
	id: string
}

export class CreateArrangementMeetingImpl implements CreateArrangementMeeting {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface FinishArrangement {
  id: string
  star?: number
  remark?: string
  lessonTestScore?: number

  reset(): void
}

export class FinishArrangementImpl implements FinishArrangement {
  id: string
  star?: number
  remark?: string
  lessonTestScore?: number

  constructor(id: string) {
    this.id = id
  }

  reset(): void {
    this.star = undefined
    this.remark = undefined
    this.lessonTestScore = undefined
  }
}

export interface CancelArrangement {
  id: string
  cancelReason?: CancelReason
  cancelRemark?: string

  reset(): void
}

export class CancelArrangementImpl implements CancelArrangement {
  id: string
  cancelReason?: CancelReason
  cancelRemark?: string

  constructor(id: string) {
    this.id = id
  }

  reset(): void {
    this.cancelReason = undefined
    this.cancelRemark = undefined
  }
}