export enum Direction {
	ASC = "ASC",
	DESC = "DESC"
}

export interface Sort {
	field: string
	direction: Direction
}

export class SortImpl implements Sort {
	field: string
	direction: Direction

	constructor(field: string, direction: Direction) {
		this.field = field
		this.direction = direction
	}
}