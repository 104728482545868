import { Option, OptionImpl } from "."
import { Status, CancelReason } from "../../arrangement"

export class ArrangementCancelReasonOptions {
	static options: Option[] = [
		new OptionImpl('All Cancel Reasons', CancelReason.ALL),
    new OptionImpl('Student Leave', CancelReason.LEAVE_STUDENT),
		new OptionImpl('Teacher Leave', CancelReason.LEAVE_TEACHER),
		new OptionImpl('Student Absent', CancelReason.ABSENT_STUDENT),
    new OptionImpl('Teacher Absent', CancelReason.ABSENT_TEACHER),
		new OptionImpl('Others', CancelReason.OTHERS)
	]
}

export class ArrangementTeacherCancelReasonOptions {
	static options: Option[] = [
		new OptionImpl('Ask for leave', CancelReason.LEAVE_TEACHER),
		new OptionImpl('Student is absent', CancelReason.ABSENT_STUDENT),
		new OptionImpl('Others', CancelReason.OTHERS)
	]
}

export class ArrangementStatusOptions {
	static options: Option[] = [
		new OptionImpl('All Status', Status.ALL),
    new OptionImpl('Booked', Status.BOOKED),
		new OptionImpl('Finished', Status.FINISHED),
		new OptionImpl('Canceled', Status.CANCELED),
	]
}