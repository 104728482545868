import { Result } from "../types/common/result"
import service from "./api-service"

const api = '/zoneId'

const zoneId = {
  findAvailable () {
    return service.get<Result<string[]>>(api + '/findAvailable')
  }
}

export default zoneId