import { Result } from "../types/common/result";
import { TeachingTimeSlots, TimeSlot, ToggleTimeSlotAvailable } from "../types/teachingTimeSlots";
import service from "./api-service";

const api = '/teachingTimeSlots'

const teachingTimeSlotsAPI = {
  findById (id: string) {
    return service.get<Result<TeachingTimeSlots>>(api + '/findById', { 
        params: {
            id: id
        }
    })
  },

  findByTeacherId (teacherId: string) {
    return service.get<Result<TeachingTimeSlots>>(api + '/findByTeacherId', { 
        params: {
          teacherId: teacherId
        }
    })
  },

  toggleTimeSlotAvailable (toggleTimeSlotAvailable: ToggleTimeSlotAvailable) {
    return service.put<Result<TimeSlot>>(api + '/timeSlot/toggleAvailable', toggleTimeSlotAvailable)
  }
}

export default teachingTimeSlotsAPI