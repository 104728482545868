import { Filters } from "./index"

export interface EarningFilters extends Filters {
	teacherId: string
}

export class EarningFiltersImpl implements EarningFilters {
	teacherId: string

	constructor(teacherId: string) {
		this.teacherId = teacherId
	}
	reset(): void {
		
	}
}