<template>
  <!-- Step Button -->
  <div class="step" :class="{active: active}">
    <button type="button" class="step-trigger" role="tab">
      <span class="bs-stepper-circle">{{ step }}</span>
      <span class="bs-stepper-label">{{ label }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance } from 'vue';

const props = defineProps<{
  step: number,
  label: string
}>()

const instance = getCurrentInstance()

const active = computed(() => {
  return props.step == instance?.parent?.props.activeStep
})
</script>
