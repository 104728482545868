<template>
  <div v-if="editCertificates" class="d-flex justify-content-between align-items-center">
    <label for="certificates" class="form-label">Certificates (Optional): currently only TEFL and TESOL certificates are acknowledged. Having certain certificates may have a positive impact on your wage.</label>
    <it-button kind="secondary" outline size="sm" style="width: 200px;" @click="doCreateCertificate">Add Certificate</it-button>
  </div>
  <div class="mt-2" v-for="(certificate, index) in teacherCertificates" :key="index">
    <teacher-certificate-uploader :certificate="certificate" :index="index" :edit="editCertificates" @upload="doUploadCertificate" @delete="doDeleteCertificate"></teacher-certificate-uploader>
  </div>
</template>

<script setup lang="ts">
import { Certificate, CertificateImpl } from '../../types/certificate'
import { useModelWrapper } from '../../utils/modelWrapper'
import TeacherCertificateUploader from '../certificate/TeacherCertificateUploader.vue'
import ItButton from '../ui/ItButton.vue'

export interface Props {
  certificates?: Certificate[],
  edit?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  edit: true
})

const emit = defineEmits<{
  (e: 'update:certificates'): void,
  (e: 'update:edit'): void,
  (e: 'update'): void
}>()

const teacherCertificates = useModelWrapper(props, emit, 'certificates')
const editCertificates = useModelWrapper(props, emit, 'edit')

const doCreateCertificate = function () {
  if (teacherCertificates.value) {
    teacherCertificates.value.push(new CertificateImpl())
  } else {
    teacherCertificates.value = [new CertificateImpl()]
  }
}

const doUploadCertificate = function () {
  emit('update')
}

const doDeleteCertificate = function (index: number) {
  teacherCertificates.value.splice(index, 1)
  emit('update')
}
</script>
