<template>
  <it-dialog :id="id" :header="true" :footer="true" @show="onShow">
    <template #header>
      <h4 class="modal-title">Cancel Class</h4>
    </template>
    <template #body>
      <form ref="cancelArrangementForm" :class="{'was-validated': cancelArrangementFormValidated}" novalidate>
        <!-- Alert -->
        <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
        <!-- Cancel Reason -->
        <div>
          <label for="cancelReason" class="form-label">Cancel Reason</label>
          <div id="cancelReason">
            <div class="form-check" v-for="cancelReasonOption in ArrangementTeacherCancelReasonOptions.options" :key="cancelReasonOption.value.toString" :value="cancelReasonOption.value">
              <input class="form-check-input" type="radio" v-model="cancelArrangement.cancelReason" :id="cancelReasonOption.value.toString" :name="cancelReasonOption.value.toString" :value="cancelReasonOption.value" required>
              <label class="form-check-label" :for="cancelReasonOption.value.toString">{{ cancelReasonOption.label }}</label>
            </div>
          </div>
        </div>
        <!-- Cancel Remark -->
        <div v-if="cancelArrangement.cancelReason === CancelReason.OTHERS" class="mt-3">
          <label for="cancelRemark" class="form-label">Cancel Remark</label>
          <textarea ref="cancelRemarkInput" rows="5" v-model.trim="cancelArrangement.cancelRemark" id="cancelRemark" class="form-control" name="cancelRemark" required @input="onCancelRemarkInput"></textarea>
          <div class="invalid-feedback" :class="{invalid: cancelRemarkInputInvalid}">You must input cancel remark.</div>
        </div>
      </form>
    </template>
    <template #footer>
      <it-button kind="secondary" size="sm" data-bs-dismiss="modal">Cancel</it-button>
      <it-button kind="primary" size="sm" :loading="canceling" @click="doCancelArrangement">Confirm</it-button>
    </template>
  </it-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, reactive, ref, toRef } from 'vue'
import arrangementAPI from '../../api/arrangement'
import { toast } from '../../injection'
import { Arrangement, CancelArrangement, CancelArrangementImpl, CancelReason } from '../../types/arrangement'
import { ArrangementTeacherCancelReasonOptions } from "../../types/common/option/arrangementOptions"
import { Result } from '../../types/common/result'
import ItButton from '../ui/ItButton.vue'
import ItDialog from '../ui/ItDialog.vue'

const props = defineProps<{
  arrangementId: string,
  id: string
}>()

const emit = defineEmits<{
  (e: 'success'): void
}>()

const toastRef = inject(toast)

const arrangementIdRef = toRef(props, 'arrangementId')

const message = ref<string>('')

const cancelArrangement = reactive<CancelArrangement>(new CancelArrangementImpl(props.arrangementId))
const cancelArrangementForm = ref<HTMLFormElement>()

const canceling = ref<boolean>(false)
const cancelArrangementFormValidated = ref<boolean>(false)

const onShow = function () {
  message.value = ''
  cancelArrangementFormValidated.value = false
  cancelArrangement.reset()
}

const cancelRemarkInput = ref<HTMLInputElement>()
const cancelRemarkInputInvalid = ref<boolean>(false)
const onCancelRemarkInput = function() {
  cancelRemarkInput.value?.setCustomValidity('')
  cancelRemarkInputInvalid.value = false
}

const doCancelArrangement = function () {
  if (cancelArrangement.cancelReason === CancelReason.OTHERS && !cancelRemarkInput.value?.checkValidity()) {
    cancelRemarkInputInvalid.value = true
    return
  } 

  canceling.value = true
  cancelArrangementFormValidated.value = true
  cancelArrangement.id = arrangementIdRef.value
  arrangementAPI.cancel(cancelArrangement).then((response: AxiosResponse<Result<Arrangement>>) => {
    const result = response.data
    if (result.success) {
      cancelArrangementFormValidated.value = false
      toastRef?.value?.success('Success to cancel')
      emit('success')
    } else {
      message.value = result.message
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    canceling.value = false
  })
}
</script>

<style lang="scss" scoped>

.invalid {
  display: block;
}

</style>