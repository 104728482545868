<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">Settings</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <div>
        <label for="zoneId" class="form-label">Time Zone</label>
        <select v-model="updateTeacherZoneId.zoneId" id="zoneId" class="form-select" name="zoneId" @change="doUpdateTeacherZoneId">
          <option v-for="(availableZoneId, index) in availableZoneIds" :key="index" :value="availableZoneId">{{ availableZoneId }}</option>
        </select>
      </div>
      <div class="d-grid mt-4">
        <it-button kind="danger" @click="doLogout">Logout</it-button>
      </div>
    </div>
  </div>
  <!-- Version -->
  <div class="text-center mt-3">- {{ version }} -</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { computed, inject, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import config from '../../../package.json'
import teacherAPI from '../../api/teacher'
import zoneIdAPI from '../../api/zoneId'
import ItButton from '../../components/ui/ItButton.vue'
import { toast } from '../../injection'
import { Actions, useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Teacher, UpdateTeacherZoneId, UpdateTeacherZoneIdImpl } from '../../types/teacher'

const toastRef = inject(toast)

const version = computed(() => config.version)

const store = useStore()
const teacher = store.state.teacher!

onMounted(() => {
  doFindAvailableZoneIds()
})

const availableZoneIds = ref<string[]>()
const doFindAvailableZoneIds = function () {
  zoneIdAPI.findAvailable().then((response: AxiosResponse<Result<string[]>>) => {
    const result = response.data
    if (result.success) {
      availableZoneIds.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const updateTeacherZoneId = reactive<UpdateTeacherZoneId>(new UpdateTeacherZoneIdImpl(teacher.id!, teacher.zoneId))
const doUpdateTeacherZoneId = function () {
  teacherAPI.updateZoneId(updateTeacherZoneId).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      const teacher = result.data
      store.dispatch(Actions.CHANGE_TEACHER_ZONE_ID, teacher.zoneId).then(() => {
        toastRef?.value?.success('Success to change time zone.')
      })
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const router = useRouter()
const doLogout = function() {
	store.dispatch(Actions.LOGOUT).then(() => {
		router.push({	name: 'auth' })
	})
}
</script>
