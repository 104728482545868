<template>
  <div class="card">
    <!-- Card body -->
    <div class="card-body">
      <!-- Time Slots -->
      <it-tabs :active-index="activeTabIndex">
        <template #tabs>
          <it-tab v-for="(dayOfWeek, index) in dayOfWeeks" :key="index" :index="index" @select="onTabSelect">{{ dayOfWeek }}</it-tab>
        </template>

        <template #panes>
          <it-tab-pane class="mt-4" v-for="(dayOfWeek, index) in dayOfWeeks" :key="index" :index="index">
            <div class="table-responsive border-0">
              <table class="table mb-0">
                <thead class="table-light">
                  <tr>
                    <th scope="col" class="border-0">#</th>
                    <th scope="col" class="border-0">Status</th>
                    <th scope="col" class="border-0">Arrange</th>
                    <th scope="col" class="border-0">Lesson</th>
                    <th scope="col" class="border-0">Slide</th>
                    <th scope="col" class="border-0">Zoom</th>
                    <th scope="col" class="border-0">Actions</th>
                  </tr>
                </thead>
                <tbody v-if="arrangements && arrangements?.length > 0">
                  <tr v-for="(arrangement, index) in arrangements" :key="arrangement.id">
                    <th scope="row" class="align-middle border-top-0">{{ index + 1 }}</th>
                    <td class="align-middle border-top-0">
                      <div>{{ ArrangementFilter.status(arrangement.status) }}</div>
                      <div>{{ ArrangementFilter.cancelReason(arrangement.cancelReason) }}</div>
                    </td>
                    <td class="align-middle border-top-0">
                      <div>{{ arrangement.studentNickname }}</div>
                      <div>{{ DateTimeFilter.date(arrangement.startDateTime) }}</div>
                      <div>{{ DateTimeFilter.time(arrangement.startDateTime) }}</div>
                    </td>
                    <td class="align-middle border-top-0">
                      <div>{{ arrangement.courseFullName }}</div>
                      <div>{{ arrangement.lessonFullName }}</div>
                      <div>{{ CourseFilter.brief(arrangement.courseType, arrangement.trial, arrangement.courseLessonDuration) }}</div>
                      <div>{{ ArrangementFilter.demo(arrangement.demo) }}</div>
                    </td>
                    <td class="align-middle border-top-0">
                      <a :href="arrangement.lessonSlide" target="_blank">Slide</a>
                    </td>
                    <td class="align-middle border-top-0" width="250">
                      <div v-if="arrangement.meetingId == 0">
                        Click Start Class to get zoom meeting id and password
                      </div>
                      <div v-else>
                        <div>Meeting: {{ arrangement.meetingId }}</div>
                        <div>Password: {{ arrangement.meetingPassword }}</div>
                      </div>
                    </td>
                    <td class="text-muted align-middle border-top-0 text-nowrap" width="120" style="padding: 0.75rem 1.5rem 0.75rem 0;">
                      <div>
                        <it-button kind="link" :disabled="arrangement.status !== Status.BOOKED" :loading="starting" @click="doStartClass(arrangement)">Start Class</it-button>
                      </div>
                      <div>
                        <it-button kind="link" :disabled="arrangement.status !== Status.BOOKED" @click="doFinishArrangement(arrangement)">Finish Class</it-button>
                      </div>
                      <div>
                        <it-button kind="link" :disabled="arrangement.status !== Status.BOOKED" @click="doCancelArrangement(arrangement)">Cancel Class</it-button> 
                      </div>
                    </td>
                  </tr>
                </tbody>
                <caption v-else style="text-align: center;">No Arrangement</caption>
              </table>
            </div>
          </it-tab-pane>
        </template>
      </it-tabs>  
    </div>
  </div>

  <start-class-dialog id="startClassDialog"></start-class-dialog>

  <finish-arrangement-dialog id="finishArrangementDialog" :arrangement-id="arrangementId" :arrangement-lesson-type="arrangementLessonType" :arrangement-lesson-total-test-score="arrangementLessonTotalScore" @success="onFinishArrangementSuccess"></finish-arrangement-dialog>

  <cancel-arrangement-dialog id="cancelArrangementDialog" :arrangement-id="arrangementId" @success="onCancelArrangementSuccess"></cancel-arrangement-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { Modal } from 'bootstrap'
import { inject, onMounted, ref } from 'vue'
import arrangementAPI from '../../api/arrangement'
import CancelArrangementDialog from '../../components/schedule/CancelArrangementDialog.vue'
import FinishArrangementDialog from '../../components/schedule/FinishArrangementDialog.vue'
import StartClassDialog from '../../components/schedule/StartClassDialog.vue'
import ItButton from '../../components/ui/ItButton.vue'
import ItTab from '../../components/ui/tab/ItTab.vue'
import ItTabPane from '../../components/ui/tab/ItTabPane.vue'
import ItTabs from '../../components/ui/tab/ItTabs.vue'
import { createDayOfWeeks } from '../../factory'
import { ArrangementFilter } from '../../filters/arrangementFilter'
import { CourseFilter } from '../../filters/courseFilter'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { Arrangement, CreateArrangementMeetingImpl, Status } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import { Type as LessonType } from '../../types/lesson'
import { getThisDateOfWeek, getToday, getWeekday, isMinutesAfterNow } from '../../utils/dateTimeUtils'

const toastRef = inject(toast)

const store = useStore()

const activeDate = ref<Date>(getToday())
const activeTabIndex = ref<number>(getWeekday(activeDate.value) - 1)

const onTabSelect = function (index: number) {
  activeTabIndex.value = index
  activeDate.value = getThisDateOfWeek(index)
  doFindArrangement()
}

const dayOfWeeks = createDayOfWeeks()

const arrangements = ref<Arrangement[]>()

let startClassDialog: Modal | undefined = undefined
let finishArrangementDialog: Modal | undefined = undefined
let cancelArrangementDialog: Modal | undefined = undefined

onMounted(() => {
  doFindArrangement()

  startClassDialog = new Modal(document.getElementById('startClassDialog')!)
  finishArrangementDialog = new Modal(document.getElementById('finishArrangementDialog')!)
  cancelArrangementDialog = new Modal(document.getElementById('cancelArrangementDialog')!)
})

// Find arrangement
const finding = ref<boolean>(false)
const doFindArrangement = function () {
  finding.value = true
  arrangementAPI.findByTeacherIdAndDate(store.getters.id, activeDate.value).then((response: AxiosResponse<Result<Arrangement[]>>) => {
    const result = response.data
    if (result.success) {
      arrangements.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    finding.value = false
  })
}

// Start class
const starting = ref<boolean>(false)
const doStartClass = function (arrangement: Arrangement) {
  if (isMinutesAfterNow(arrangement.startDateTime!, 10)) {
    startClassDialog?.show()
  } else {
    const startClassWindow = window.open()
    if (startClassWindow) {
      if (arrangement.startUrl) {
        startClassWindow.location = arrangement.startUrl
      } else {
        starting.value = true
        const createMeeting = new CreateArrangementMeetingImpl(arrangement.id)
        arrangementAPI.createMeeting(createMeeting).then((response: AxiosResponse<Result<Arrangement>>) => {
          const result = response.data
          if (result.success) {
            const arrangement = result.data
            if (arrangement.startUrl) {
						  startClassWindow.location = arrangement.startUrl
            } else {
              toastRef?.value?.error('Can not create zoom meeting')
            }
            doFindArrangement()
          } else {
            toastRef?.value?.error(result.message)
          }
        }).catch((error: AxiosError) => {
          toastRef?.value?.error(error.message)
        }).finally(() => {
          starting.value = false
        })
      }
    } else {
      toastRef?.value?.error('Can not open window for Zoom')
    }
  }
}

const arrangementId = ref<string>('')
const arrangementLessonType = ref<LessonType>(LessonType.LECTURE)
const arrangementLessonTotalScore = ref<number>()

// Finish arrangement
const doFinishArrangement = function (arrangement: Arrangement) {
  arrangementId.value = arrangement.id
  arrangementLessonType.value = arrangement.lessonType!
  arrangementLessonTotalScore.value = arrangement.lessonTotalTestScore
  finishArrangementDialog?.show()
}
const onFinishArrangementSuccess = function () {
  finishArrangementDialog?.hide()
  doFindArrangement()
}

// Cancel arrangement
const doCancelArrangement = function (arrangement: Arrangement) {
  arrangementId.value = arrangement.id
  cancelArrangementDialog?.show()
}
const onCancelArrangementSuccess = function () {
  cancelArrangementDialog?.hide()
  doFindArrangement()
}
</script>

<style>
table tr:last-child th {
  border-bottom-width: 0 !important
}

table tr:last-child td {
  border-bottom-width: 0 !important
}
</style>