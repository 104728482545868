import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { Enrollment } from "../types/enrollment"
import service from "./api-service"

const api = '/enrollment'

const enrollmentAPI = {
  findById (id: string) {
    return service.get<Result<Enrollment>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  }
}

export default enrollmentAPI