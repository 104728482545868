<template>
  <teacher-avatar-uploader v-model:avatar="updateTeacherAvatar.avatar" v-model:edit="edit" @success="doUpdateTeacherAvatar"></teacher-avatar-uploader>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, reactive, ref } from 'vue'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { Actions, useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Teacher, UpdateTeacherAvatar, UpdateTeacherAvatarImpl } from '../../types/teacher'
import TeacherAvatarUploader from './TeacherAvatarUploader.vue'

const toastRef = inject(toast)

const store = useStore()
const teacher = store.state.teacher

const edit = ref<boolean>(false)

const updateTeacherAvatar = reactive<UpdateTeacherAvatar>(new UpdateTeacherAvatarImpl(teacher))

const doUpdateTeacherAvatar = function () {
  teacherAPI.updateAvatar(updateTeacherAvatar).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      store.dispatch(Actions.CHANGE_TEACHER_AVATAR, result.data.avatar).then(() => {
        edit.value = false
        toastRef?.value?.success('Success to update')
      })
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}
</script>
