import { TeacherAuthed, TeacherEmailOtpAuth, TeacherEmailPasswordAuth, TeacherMobileOtpAuth } from "../types/auth"
import { Result } from "../types/common/result"
import service from "./auth-service"

const api = '/teacher/auth'

const authAPI = {
    emailOtpAuth (teacherEmailOtpAuth: TeacherEmailOtpAuth) {
        return service.post<Result<TeacherAuthed>>(api + '/email-otp', teacherEmailOtpAuth)
    },

    emailPasswordAuth (teacherEmailPasswordAuth: TeacherEmailPasswordAuth) {
        return service.post<Result<TeacherAuthed>>(api + '/email-password', teacherEmailPasswordAuth)
    },

    mobileOtpAuth (teacherMobileOtpAuth: TeacherMobileOtpAuth) {
        return service.post<Result<TeacherAuthed>>(api + '/mobile-otp', teacherMobileOtpAuth)
    }
}

export default authAPI