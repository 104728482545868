<template>
  <!-- Card -->
  <div class="card mb-3">
    <!-- Card header -->
    <div class="card-header border-bottom px-4 py-3">
      <h4 class="mb-0">Basic Information</h4>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <form ref="completeTeacherBasicInfoForm" class="row g-3" :class="{'was-validated': completeTeacherBasicInfoFormValidated}" novalidate>
        <!-- Alert -->
        <div v-if="message" class="col-12 alert alert-danger" role="alert">{{ message }}</div>
        <!-- Avatar -->
        <teacher-avatar-uploader v-model:avatar="completeTeacherBasicInfo.avatar"></teacher-avatar-uploader>
        <!-- Name -->
        <div class="col-md-6">
          <label for="first-name" class="form-label">First Name</label>
          <input v-model.trim="completeTeacherBasicInfo.firstName" type="text" id="first-name" class="form-control" name="first-name" required />
          <div class="invalid-feedback">You must input valid name.</div>
        </div>
        <div class="col-md-6">
          <label for="last-name" class="form-label">Last Name</label>
          <input v-model.trim="completeTeacherBasicInfo.lastName" type="text" id="last-name" class="form-control" name="last-name" required />
          <div class="invalid-feedback">You must input valid name.</div>
        </div>
        <!-- Nickname -->
        <div class="col-12">
          <label for="nickname" class="form-label">Nickname</label>
          <input v-model.trim="completeTeacherBasicInfo.nickname" type="text" id="nickname" class="form-control" name="nickname" required />
          <div class="invalid-feedback">You must input valid nickname.</div>
        </div>
        <!-- Country -->
        <div class="col-md-6">
          <label for="country" class="form-label">Country</label>
          <select v-model="completeTeacherBasicInfo.country" id="country" class="form-select" name="country" required>
            <option v-for="country in countries" :key="country.code" :value="country.code">{{ country.name }}</option>
          </select>
          <div class="invalid-feedback">You must select your country.</div>
        </div>
        <!-- Accent -->
        <div class="col-md-6">
          <label for="accent" class="form-label">Accent</label>
          <select v-model="completeTeacherBasicInfo.accent" id="accent" class="form-select" name="accent" required>
            <option v-for="(option, index) in AccentOptions.options" :key="index" :value="option.value">{{ option.label }}</option>
          </select>
          <div class="invalid-feedback">You must select your accent.</div>
        </div>
        <!-- Promote Code -->
        <div class="col-12">
          <label for="promote-code" class="form-label">Promo Code</label>
          <input v-model.trim="completeTeacherBasicInfo.promoteCode" type="text" id="promote-code" class="form-control" name="promote-code" />
        </div>
        <!-- Button -->
        <div class="d-grid">
          <it-button kind="primary" :loading="completing" @click="doCompleteTeacherBasicInfo">Complete Basic Info</it-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref } from 'vue'
import countryAPI from '../../api/country'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { Actions, useStore } from '../../store'
import { AccentOptions } from '../../types/common/option/accentOptions'
import { Result } from '../../types/common/result'
import { Country } from '../../types/country'
import { CompleteTeacherBasicInfo, CompleteTeacherBasicInfoImpl, Teacher } from '../../types/teacher'
import TeacherAvatarUploader from '../me/TeacherAvatarUploader.vue'
import ItButton from '../ui/ItButton.vue'

const emit = defineEmits<{
  (e: 'success'): void
}>()

const toastRef = inject(toast)

onMounted(() => {
  doFindAllCountries()
})

const store = useStore()
const teacher = store.state.teacher!

const message = ref<string>('')

// country
const countries = ref<Country[]>()
const doFindAllCountries = function () {
  countryAPI.findAll().then((response: AxiosResponse<Result<Country[]>>) => {
    const result = response.data
    if (result.success) {
      countries.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const completeTeacherBasicInfo = reactive<CompleteTeacherBasicInfo>(new CompleteTeacherBasicInfoImpl(teacher.id!))
const completeTeacherBasicInfoForm = ref<HTMLFormElement>()

const completing = ref<boolean>(false)
const completeTeacherBasicInfoFormValidated = ref<boolean>(false)
const doCompleteTeacherBasicInfo = function () {
  completeTeacherBasicInfoFormValidated.value = true
  if (completeTeacherBasicInfoForm.value?.checkValidity()) {
    completing.value = true
    teacherAPI.completeBasicInfo(completeTeacherBasicInfo).then((response: AxiosResponse<Result<Teacher>>) => {
      const result = response.data
      if (result.success) {
        store.dispatch(Actions.COMPLETE_TEACHER_BASIC_INFO, result.data).then(() => emit('success'))
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      completing.value = false
    })
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  .form-select {
    flex-grow: 1;
  }

  .form-control {
    flex-grow: 5;
  }
} 

</style>