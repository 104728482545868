import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { Earning } from "../types/earning"
import service from "./api-service"

const api = '/earning'

const earningAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Earning>>>(api + '/list', query)
  }    
}

export default earningAPI