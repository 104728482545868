export enum Gender {
  UNKNOWN = 'UNKNOWN', // 未知
  BOY = 'BOY', // 男孩
  GIRL = 'GIRL' // 女孩
}

export enum Accent {
  AMERICAN = 'AMERICAN',
  BRITISH = 'BRITISH'
}

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}