<template>
  <div v-if="edit">
    <div class="input-group">
      <select v-model="certificate.name" class="form-select" required>
        <option v-for="(option, index) in CertificateNameOptions.options" :key="index" :value="option.value">{{ option.label }}</option>
      </select>
      <input type="file" id="show" class="form-control" name="show" accept="image/*" @change="beforeUploadCertificatePhoto" required>
      <it-loading :loading="uploading"></it-loading>
      <it-button kind="secondary" outline @click="doDeleteCertificate">Delete</it-button>
    </div>
    <small class="mt-2 d-block">
      Upload your certificate photo here. It must meet our certificate photo quality standards.
      Important guidelines: jpg, jpeg, or png format; less than 1M.
    </small>
    <small class="mt-2 error" v-if="message">{{ message }}</small>
  </div>
  
  <img class="mt-2" style="width: 100%;" v-if="certificate.photo" :src="certificate.photo">
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, ref } from 'vue'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { Certificate } from '../../types/certificate'
import { CertificateNameOptions } from '../../types/common/option/certificateNameOptions'
import { Result } from '../../types/common/result'
import ItButton from '../ui/ItButton.vue'
import ItLoading from '../ui/ItLoading.vue'

export interface Props {
  certificate: Certificate,
  index: number
  edit?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  edit: true
})

const emit = defineEmits<{
  (e: 'upload'): void,
  (e: 'delete', index: number): void
}>()

const toastRef = inject(toast)

const uploading = ref<boolean>(false)

const message = ref<string>('')
const beforeUploadCertificatePhoto = function (event: Event) {
  const target = event.target as HTMLInputElement
  if (target.files && target.files[0]) {
    const file = target.files[0]
    // 判断大小
    if (file.size > 1024 * 1024) {
      message.value = 'Please upload photo which size is less than 1M'
    } else {
      doUploadCertificatePhoto(file)
    }
  }
}
const doUploadCertificatePhoto = function (photo: File) {
  uploading.value = true
  teacherAPI.uploadCertificatePhoto(photo).then((response: AxiosResponse<Result<string>>) => {
    const result = response.data
    if (result.success) {
      props.certificate.photo = result.data
      emit('upload')
    } else {
      message.value = result.message
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    uploading.value = false
  })
}

const doDeleteCertificate = function () {
  emit('delete', props.index)
}
</script>

<style lang="scss" scoped>
.input-group {
  .form-select {
    flex-grow: 1;
  }

  .form-control {
    flex-grow: 9;
  }
} 

</style>