import { Pagination } from "./pagination";
import { Searcher } from "./searcher/index";
import { Sort } from "./sort";

export interface Query {
	filters?: any
	searcher?: Searcher
	sorts?: Sort[]
	page: number
	size: number
}

export class QueryImpl implements Query {
	filters?: any
	searcher?: Searcher
	sorts?: Sort[]
	page: number
	size: number

	constructor(pagination: Pagination, filters?: any, searcher?: Searcher, sorts?: Sort[]) {
		this.filters = filters
		this.searcher = searcher
		this.sorts = sorts
		this.page = pagination.currentPage
		this.size = pagination.pageSize
	}
}