import { Result } from "../types/common/result"
import { Country } from "../types/country"
import service from "./api-service"

const api = '/country'

const countryAPI = {      
  findAll () {
    return service.get<Result<Country[]>>(api + '/findAll', { 
      params: {
        language: 'en'
      }
    })
  }
}

export default countryAPI