<template>
  <div class="row">
    <div class="col-lg-4 col-md-12 col-12">
      <!-- Card -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="h1 fw-bold mb-0 mt-4 lh-1">$ {{ NumberFilter.money(teacher.balance) }}</h2>
          <p>Account Balance</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-12">
      <!-- Card body -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="h1 fw-bold mb-0 mt-4 lh-1">$ {{ NumberFilter.money(teacher.totalEarning) }}</h2>
          <p>Total Earning</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-12">
      <!-- Card body -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="h1 fw-bold mb-0 mt-4 lh-1">$ {{ NumberFilter.money(teacher.totalWithdraw) }}</h2>
          <p>Total Withdraw</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive } from 'vue'
import teacherAPI from '../../api/teacher'
import { NumberFilter } from '../../filters/numberFilter'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Teacher, TeacherImpl } from '../../types/teacher'
import { cloneFrom } from '../../utils/clone'

const toastRef = inject(toast)

const store = useStore()

const teacher = reactive<Teacher>(new TeacherImpl(store.getters.id))
const doFindTeacher = function () {
    teacherAPI.findById(store.getters.id).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(teacher, result.data)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

onMounted(() => {
  doFindTeacher()
})
</script>
